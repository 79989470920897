@import '../assets/fonts/YekanBakh/css/font.css', 'src/styles/references/index',
'../assets/fonts/Montserrat/css/font.css', '../assets/fonts/Roboto/css/font.css';


html,
body {
  background: $new-bg !important;
  overflow-x: hidden;
}

.Toastify {
  .Toastify__toast-container {
    width: fit-content;
    dir: rtl;

    @include xpay-pre-lg {
      top: 32px;
      left: 50%;
      width: max-content;
      transform: translateX(-50%);
    }

    &.Toastify__toast-container--bottom-right {
      top: initial;
      left: initial;
      width: initial;
      transform: initial;
      bottom: 16px;
    }

    .Toastify__toast {

    }
  }
}

.Toastify__toast-body {
  @include xpay-pre-lg {
    max-width: 240px;
  }
}

.xpay-toast-container {

}

@include xpay-pre-lg {
  .desktop-show {
    display: none !important;
  }
}

.ant-drawer-content-wrapper {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.ant-drawer-content-wrapper {
  height: auto !important;
  max-height: max-content !important;
}

.ant-drawer-mask,
.ant-drawer-content-wrapper {
  display: none !important;

  @include xpay-pre-lg {
    display: unset !important;
  }
}

.ant-drawer-content-wrapper-hidden {
  @include xpay-pre-lg {
    display: none !important;
  }
}

.mobile-show {
  display: none !important;

  @include xpay-pre-lg {
    display: unset !important;

    .ant-drawer-mask,
    .ant-drawer-content-wrapper {
      display: none !important;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 9998 !important;
  transform: translate3d(-100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translate3d(-100px);
}

.xpay-dashboard-main::-webkit-scrollbar {
  width: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $input inset !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-popover-inner-content {
  width: max-content !important;
  margin: 0 auto !important;
}

body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  direction: rtl;
  position: relative; //for mobile header handling

  &.scrollbar-none {
    scrollbar-width: none !important;
  }
}

p {
  margin-bottom: 0px !important;
}

.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.d-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

h1 {
  font-weight: bold !important;
}

body,
a,
button,
li,
ul,
div,
p,
table,
tbody,
thead,
tr,
td,
th,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: YekanBakh !important;
}

span {
  font-family: YekanBakh;
}

input {
  font-family: YekanBakh !important;
}

span.danger {
  color: $danger;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.d-none {
  display: none !important;
}

.v-none {
  visibility: hidden !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.ant-badge-status-text {
  margin-right: 10px !important;
}

.ant-popover {
  text-align: right !important;
  transition: 2s;
  max-width: 285px !important;

  .ant-popover-title {
    padding: 9px;
    font-weight: 800;
  }

  ul {
    padding: 5px;
    list-style: none;

    li {
      border-bottom: 0.5px solid $ant-form-bg;
      padding-bottom: 7px;
      margin-top: 7px;
      padding: 5px;
      font-size: 12px;

      &:hover {
        cursor: pointer;
      }

      &.danger {
        background: $popover-danger;
      }

      &.warn {
        background: $popover-warn;
      }

      &.success {
        background: $popover-success;
      }

      &.primary {
        background: aliceblue;
      }
    }
  }
}

.ant-form-item-control-input-content {
  input,
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    padding: 9px 12px !important;
    border: 0;
    background: $ant-form-bg !important;
    outline: 0;
    border-radius: 5px;
  }
}

.ant-modal {
  top: 25% !important;

  .ant-modal-close {
    right: 90%;
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 0 !important;
    margin-right: 8px !important;
  }
}

.ant-select-selection-item {
  .ant-select-selection-item-content {
    font-family: Roboto !important;
    margin-left: 4px;
  }
}

.ant-select-selection-overflow-item {
  .ant-tag {
    text-align: right;

    .anticon-close {
      margin-right: 2px;
    }
  }
}

.en {
  font-family: Roboto !important;
}

.fa {
  font-family: YekanBakh !important;
}

.primary {
  color: $info;
}

.succssed {
  color: $light-success;
}

.ant-message-notice-content {
  font-size: 18px;
}

.ant-message-custom-content {
  align-items: center !important;

  span:nth-child(2) {
    margin-right: 13px;
  }
}

.ant-message .anticon {
  font-size: 18px !important;
  padding-bottom: 6px !important;
}

.ant-steps-item-icon .ant-steps-icon {
  top: 1px !important;
}

.ant-table-cell {
  text-align: right !important;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

@include xpay-pre-lg {
  .ant-pagination-item {
    min-width: 25px;
  }
  .ant-pagination {
    justify-content: center;
  }
  .ant-table-column-title {
    z-index: 0 !important;
    font-size: 14px;
  }
}

.xpay-skeleton {
  background: $skeleton-bg;
  display: block;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateX(-100%);
    background-image: linear-gradient(
                    90deg,
                    rgba($skeleton-bg-a, 0) 0,
                    rgba($skeleton-bg-b, 0.3) 50%,
                    rgba($skeleton-bg-c, 0)
    );
    animation: skeleton-background-animation 4s infinite;
    content: '';
  }

  @keyframes skeleton-background-animation {
    100% {
      transform: translateX(100%);
    }
  }
}

.open-tooltip {
  text-align: right !important;
}

.ant-space-compact {
  border: #4096ff solid 1px;
  border-radius: 8px;
  width: max-content;
  display: flex;
  justify-content: space-between;

  button {
    height: 42px !important;
    border-radius: 8px;
    border: 0px;
  }

  button:nth-child(2) {
    border-right: 0px;

    .anticon {
      margin-top: 8px;
    }
  }
}

.xpay-ul {
  border-radius: 8px;
  list-style: none;
  padding: 16px !important;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span.en {
      font-family: Roboto !important;
      text-align: left;
    }

    span {
      font-size: 14px !important;
    }
  }
}

.fields {
  column-gap: 16px;

  .input-field,
  input {
    height: 38px;
  }
}

.ant-dropdown-trigger {
  button {
    justify-content: space-between;
    width: max-content !important;
    min-width: 130px !important;
  }
}

.ant-spin-nested-loading {
  width: 100% !important;
}

.mobile-show {
  li {
    row-gap: 16px !important;
  }

  .ant-dropdown-trigger button {
    width: 100% !important;
  }

  .filter-row {
    h4 {
      margin: 24px 0 24px auto;
    }
  }

  .filter-row .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .xpay-input:first-child {
      flex: 90% !important;
    }

    > div {
      margin-bottom: 16px;
      flex: 45%;

      .input-field input {
        height: 38px !important;
      }

      .xpay-input {
        width: 130px !important;
        margin-right: 17px;
        width: 100% !important;
      }

      &:first-child,
      &:nth-child(2),
      :nth-child(3) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .xpay-dropdown,
      &.xpay-dropdown {
        min-width: unset !important;
        max-width: unset !important;
        border-radius: 10px;
        padding: 0 16px;

        > .dropdown-selector {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          svg {
            margin-left: 8px;
            width: 15px;
            height: 15px;
          }
        }

        > .dropdown-items {
          width: 100%;
          max-width: unset;
          margin: 0;

          .dropdown-item {
            img {
              margin-left: 8px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }

      &:first-child {
        .xpay-dropdown {
          width: 50%;

          &:last-child {
            margin-right: 16px;
          }
        }
      }

      &:nth-child(2) {
        .xpay-calendar {
          &:last-child {
            margin-right: 16px;
          }
        }
      }
    }

    div:last-child:not(.xpay-dropdown) {
      margin-right: 2px !important;

      @include xpay-pre-lg {
        margin-right: 0px !important;
      }
    }
  }

  .filter-row .buttons {
    margin-right: 7px;

    @include xpay-pre-lg {
      margin-right: 0;
    }

    button {
      height: 38px !important;
    }
  }
}

//.ReactModal__Content {
//  @include xpay-pre-lg {
//    width: 356px !important;
//  }
//}

.xpay-button button span {
  @include xpay-pre-lg {
    font-size: 12px;
  }
}

.nowrap {
  white-space: nowrap;
}

.xpay-goftino-desktop {
  position: fixed;
  bottom: 32px;
  left: 32px;
  height: 40px;
  width: 180px;
  z-index: 1000;
  cursor: pointer;
  transition: all .3s ease;

  @include xpay-pre-lg {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}