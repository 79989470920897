@import "src/styles/references/index";

.xpay-transaction-card {
  background: $transaction-card-bg;
  display: flex;
  width: 100%;
  height: 75px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  margin: 16px auto;
  box-shadow: 0px 100.05756378173828px 80.04605102539062px 0px rgba(93, 128, 248, 0.1);

  border-radius: 10px;

  @include xpay-pre-lg {
    max-width: unset;
  }

  > span {
    width: 4px;
    height: 40px;
    border-radius: 2.45px;
  }

  .transaction-card-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    p {
      text-align: right;
      font-size: 12px;
      font-weight: 500;
    }
    span {
      text-align: right;
      font-size: 10px;
      color: $transaction-box-placeholder;
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
    }

    .left {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
