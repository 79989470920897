@import 'styles/references/index.scss';

.referral-purchase .head .right > .mobile-show {
  display: none;
  @include xpay-pre-lg {
    display: block;
  }
}

.referral-purchase .head .right > .desktop-show {
  @include xpay-pre-lg {
    display: none;
  }
}

.xpay-dashboard-referral .referral-purchase > .card-body > .mobile-show li {
  height: 115px;
  > .row {
    .right {
      justify-content: flex-start;
      align-items: center;

      p,
      span {
        margin-left: 8px;
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }

    .left {
      justify-content: flex-end;
      align-items: center;

      p,
      span {
        margin-right: 3px;
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }
  }
}
