@import "src/styles/references/index.scss";

.xpay-google-authentication {
  display: flex;
  flex-direction: column;
  width: 40%;
  box-sizing: border-box;
  height: 100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;

  .modal-divider {
    margin: unset;
  }

  .modal-content {
    padding: unset;

    .head {
      padding: 24px;
      margin-bottom: unset;
      border-radius: 20px 20px 0 0;
    }
  }

  .step-4 {
    .card-body {
      justify-content: start;
    }
  }

  .xpay-panel-card {
    border: unset;
    padding-top: unset;

    .card-body {
      .stepper {
        display: flex;
        @include xpay-size(font-size, 16px, 12px);
        width: 100%;
        margin-top: 24px;
        margin-inline: auto;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;

        svg {
          width: 24px;
          height: 24px;
        }

        .circle {
          width: auto;


          .bottomText {
            display: flex;
            justify-content: center;
            @include xpay-size(font-size, 16px, 10px);
            font-weight: 500;
          }
        }

        hr {
          margin-inline: auto;
          min-width: 10px;
          @include xpay-size(width, 60px, 30px);
          height: 2px;
          background-color: $secondary;
          border: $secondary;
          justify-self: center;
          transform: translateY(-22px);
          opacity: 0.5;

          &.reached {
            opacity: 1;
          }
        }
      }

      @include xpay-size(font-size, 14px, 12px);
    }
  }

  .pmx-panel-card {
    .head {
      h4 {
        font-family: YekanBakh;
        font-size: 24px;
        font-weight: 900;
        color: $black;
        text-align: center;
      }
    }
  }

  .step-1 {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
        margin-top: 16px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-inline: auto;
          min-width: 232px;
          width: fit-content;
          gap: 8px;
          height: 48px;
          background-color: #D9DFFF4D;
          border-radius: 16px;
          font-weight: 500;

          span {
            font-family: Roboto;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            height: 100%;
            align-items: center;
            color: #3772FF;
          }

          svg {
            height: 100%;
          }
        }
      }

      .xpay-button {
        width: 100%;
        margin-top: 24px;
        align-self: end;

        button {
          height: 64px;
          max-width: unset;

          span {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      p {
        text-align: center;
        margin: auto;
        font-size: 14px;
      }

      .buttons {
        display: flex;
        gap: 16px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;

            @include xpay-pre-lg {
              height: 48px;
            }
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }

  .step-2 {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      section {
        white-space: break-spaces;
        display: flex;
        align-items: flex-start;
        gap: 32px;

        @include xpay-pre-lg {
          gap: unset;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }

        a {
          align-self: end;
        }

        .qr-code {
          display: flex;
          align-items: flex-start;

          svg {
            margin: unset !important;

            path {
              &:last-child {
                fill: #3772ff;
              }
            }
          }
        }
      }

      #key-link {
        display: flex;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        gap: 8px;
        align-items: center;
        color: #3772ff;
        font-size: 12px;
        justify-content: center;
        font-weight: 500;
        background: #FCFCFC;
        border: 1px solid #EBEBEB;
        border-radius: 20px;
        margin-top: 32px;
      }

      .buttons {
        display: flex;
        gap: 16px;
        height: 64px;
        margin-top: 32px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;
            height: 100%;

            @include xpay-pre-lg {
              height: 48px;
            }
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }

  .step-3 {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      p {
        margin-top: 32px;
        text-align: center;
        font-weight: 500;

        @include xpay-pre-lg {
          margin-top: unset;
        }
      }

      .code {
        margin-top: 23px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 40px;
        padding: 16px;
        border: 1px solid var(--main-30-opacity, #c2d1f7);
        background: rgba(55, 114, 255, 0.05);
        font-size: 16px;
        outline: none;
        border: none;
        height: 41px;

        svg {
          cursor: pointer;
          margin-right: 24px;
          align-items: center !important;
          justify-content: center !important;
          width: 24px;
          height: 24px;

          @include xpay-pre-lg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 16px;
        height: 64px;
        margin-top: 32px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;
            height: 100%;

            @include xpay-pre-lg {
              height: 48px;
            }
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }

  .step-4 {
    .card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin-top: 32px;
        font-weight: 500;
        font-size: 16px;
      }

      .fields {
        margin-top: 24px;
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;

        .column {
          width: 100%;

          label {
            text-align: right;
          }

          .code-input {
            display: flex;
            direction: ltr;
            width: 100% !important;
            box-sizing: border-box;
            margin: 32px 0;

            &.error {
              > div > input {
                border: 1px solid red;
              }
            }

            > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              @include xpay-pre-lg {
                justify-content: center;
              }

              > input {
                background: $white;
                border: 1px solid lightgray;
                border-radius: 10px;
                font-size: 48px;
                color: $black;
                padding: 8px;
                margin-right: 1%;

                @include xpay-pre-md {
                  margin: 0 5px;
                  width: 40px !important;
                  height: 40px !important;
                  border-radius: 5px;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 16px;
        height: 64px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;
            height: 100%;

            @include xpay-pre-lg {
              height: 48px;
            }
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }

  .step-5 {
    height: auto;
    border-radius: 40px;

    .card-body {
      display: flex;
      flex-direction: column;

      .bg {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(237, 255, 244, 1);
        width: 100%;
        border-radius: 8px;
        margin-top: 24px;
        height: 120px;

        .image-bg {
          display: flex;
          width: 72px;
          height: 72px;
          background: #36B2891A;
          padding: 5%;
          border-radius: 10px;
          justify-content: center;
          align-items: center;
          position: relative;

          img {
            width: fit-content;
            height: auto;
          }

          .union {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      h4 {
        color: rgba(54, 178, 116, 1);
        text-align: right;
        font-family: Yekan Bakh FaNum;
        font-size: 20px;
        font-weight: 500;
        margin-top: 24px;
      }

      span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
      }

      .xpay-button {
        width: 100%;
        max-width: unset;
        margin-top: 24px;
        align-items: center;

        button {
          max-width: unset;
          width: 100%;
          height: 64px;

          span {
            margin-top: unset;
          }
        }
      }
    }
  }

  .step-6 {
    .card-body {
      display: flex;
      flex-direction: column;

      > h4 {
        color: black;
        text-align: right;
        font-family: Yekan Bakh FaNum;
        font-size: 20px;
        font-weight: 500;
        margin-top: 24px;
      }

      > span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
      }

      .code-input {
        display: flex;
        direction: ltr;
        width: 100% !important;
        box-sizing: border-box;
        margin: 32px 0;

        &.error {
          > div > input {
            border: 1px solid red;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          > input {
            background: $white;
            border: 1px solid lightgray;
            border-radius: 10px;
            font-size: 48px;
            color: $black;
            padding: 8px;
            margin-right: 1%;

            @include xpay-pre-md {
              margin: 0 5px;
              width: 52px !important;
              height: 52px !important;
              border-radius: 5px;
              font-size: 20px;
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 16px;
        height: 64px;
        margin-top: 32px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;
            height: 100%;

            @include xpay-pre-lg {
              height: 48px;
            }
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }
}

@include xpay-pre-lg {
  .xpay-google-authentication {
    min-height: unset;

    .xpay-card:not(.step-5),
    .xpay-card:not(.step-0) {
      height: auto;
    }

    .card-body {
      .stepper {
        margin-top: 24px;
      }
    }

    .step-1 {
      .card-body {
        > p {
          margin-top: 24px;
        }

        section {
          flex-direction: column;
          margin-top: 24px;
          gap: 16px;

          a {
            min-height: unset;
            height: 48px;

            img,
            svg {
              // width: 170px;
              // height: 48px;
              margin: 0;
            }
          }
        }

        .xpay-button {
          margin-top: 32px;
        }
      }
    }

    .step-2 {
      .card-body {
        section {
          white-space: break-spaces;
          margin-top: 32px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-template-areas:
            "description"
            "qr"
            "link";

          @include xpay-size(grid-column-gap, 32px, 4px);

          @include xpay-pre-lg {
            margin-top: unset;
          }

          div {
            margin: 24px auto auto;
            grid-area: qr;
          }

          p {
            grid-area: description;
          }

          a {
            margin: 24px auto 0;
            grid-area: link;
            align-self: end;
          }
        }

        .buttons {
          margin-top: 24px;
          height: 64px;

          @include xpay-pre-lg {
            height: 48px;
          }
        }
      }
    }

    .step-3 {
      .code {
        height: 56px;
        box-sizing: border-box;

        span {
          font-size: 12px;
        }
      }
    }

    .step-4 {
      .card-body {
        .buttons {
          justify-self: end;
          margin-top: 32px;
          height: 64px;

          @include xpay-pre-lg {
            height: 48px;
          }

          .xpay-button {
            margin-top: unset;
          }
        }
      }
    }

    .step-5 {

      .card-body {

        > h4 {
          font-size: 16px;
        }

        > span {
          font-size: 12px;
        }

        > span {
          font-size: 12px;
        }
      }
    }

    .step-6 {
      .card-body {
        display: flex;
        flex-direction: column;

        > h4 {
          color: black;
          text-align: right;
          font-family: Yekan Bakh FaNum;
          font-size: 16px;
          font-weight: 500;
          margin-top: 16px;
        }

        > span {
          margin-top: 16px;
          font-size: 12px;
          font-weight: 500;
        }

        .code-input {
          display: flex;
          direction: ltr;
          width: 100% !important;
          box-sizing: border-box;
          margin: 16px 0;

          &.error {
            > div > input {
              border: 1px solid red;
            }
          }

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            > input {
              background: $white;
              border: 1px solid lightgray;
              border-radius: 10px;
              font-size: 16px;
              color: $black;
              padding: 8px;
              margin-right: 1%;

              @include xpay-pre-md {
                margin: 0 5px;
                width: 32px !important;
                height: 32px !important;
                border-radius: 5px;
                font-size: 20px;
              }
            }
          }
        }

        .buttons {
          width: 100%;
          gap: 16px;
          display: flex;
          margin-top: 16px;
          height: 64px;

          .xpay-button {
            width: 75%;

            button {
              max-width: unset;
              height: 64px;

              @include xpay-pre-lg {
                height: 48px;
              }

              span {
                font-size: 16px;
              }
            }

            &:last-child {
              width: 25%;
              justify-content: center;

              button {
                span {
                  color: black;
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
