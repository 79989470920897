@import 'styles/references/index.scss';

.xpay-wallet-item {
  display: flex;
  gap: 48px;
  border-top: 1px solid #d9dfff;
  padding: 24px;
  align-items: center;
  justify-content: space-between;

  @include xpay-pre-lg {
    flex-direction: column;
    gap: 16px;
  }

  @include xpay-lg {
    .currency-label, .unit-balance, .dollar-balance, .irt-balance, .buttons {
      width: 20%;
    }

    .buttons {
      .xpay-button {
        button {
          width: 56px;
        }
      }
    }
  }

  .currency-label {
    display: flex;
    align-items: center;
    gap: 8px;

    @include xpay-pre-lg {
      width: 100%;
      justify-content: space-between;
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      span {
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        color: #b3b3b3;

        &:first-child {
          color: $black;
        }
      }
    }
  }

  .unit-balance,
  .dollar-balance,
  .irt-balance {
    font-size: 12px;
    font-weight: 400;

    @include xpay-pre-lg {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    max-width: 251px;
    left: 24px;

    .deposit-button {
      button {
        border-radius: 8px;
        border: 1px solid #36b289;
        background: #fafbfc;
        padding: 5px 24px;
        height: 32px;
        max-width: 73px;

        span {
          color: #36b289;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .withdraw-button {
      button {
        border-radius: 8px;
        border: 1px solid #b23636;
        background: #fafbfc;
        padding: 5px 24px;
        height: 32px;
        max-width: 73px;

        span {
          color: #b23636;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .trade-button {
      button {
        border-radius: 8px;
        border: 1px solid #1552f0;
        background: #fafbfc;
        padding: 5px 24px;
        height: 32px;
        max-width: 73px;

        span {
          color: #1552f0;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .buy-button {
      button {
        border-radius: 8px;
        border: 1px solid #36b289;
        background: #fafbfc;
        padding: 5px 24px;
        height: 32px;
        max-width: 73px;

        span {
          color: #36b289;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .sell-button {
      button {
        border-radius: 8px;
        border: 1px solid #b23636;
        background: #fafbfc;
        padding: 5px 24px;
        height: 32px;
        max-width: 73px;

        span {
          color: #b23636;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
