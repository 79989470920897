@import "src/styles/references/index.scss";

.xpay-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  right: 0;
  background: $white;
  height: 100%;
  font-size: 18px;
  z-index: 9997;
  border-left: 1px solid $table-border;
  row-gap: 30px;

  &.open {
    transition: min-width 0.3s;
    min-width: 255px;
  }

  // @include xpay-pre-lg {
  //   height: calc(100% - 80px);
  //   width: 100%;
  //   position: fixed;
  //   transition: 0.5s ease-out;
  //   justify-content: flex-start;
  //   overflow-y: scroll;
  //   margin-top: 0;
  //   background: $white;
  //   z-index: 999;
  // }
  @include xpay-pre-lg {
    display: none;
  }

  &.closed {
    min-width: 151px;
    transition: min-width 0.3s;

    .sidebar-head {
      padding: 26px;

      a {
        display: flex;
        align-items: center;
      }
    }

    .sidebar-main {
      align-items: center;
    }
  }

  // @include xpay-pre-lg {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   background: $bg;
  //   transition: 0.5s ease-in-out;
  //   &.disActive {
  //     right: -100%;
  //   }
  // }

  .sidebar-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    border-bottom: 1px solid $table-border;
    width: 100%;
    padding: 0 5%;

    .hamburger-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 4px;
      cursor: pointer;

      span {
        display: block;
        width: 16px;
        height: 2px;
        background: $blue;
      }
    }

    a {

      img {
        width: 161px;
        height: 32px;
      }

      @include xpay-pre-lg {
        display: none;
      }
    }
  }

  .sidebar-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    row-gap: 8px;
    height: 100%;
    overflow-y: scroll;

    @include xpay-pre-lg {
      margin: 100px 0 28px;
      margin-top: 34px !important;
      margin-bottom: 0 !important;
      width: 100%;
      padding: 0px 16px;
    }

    .sidebar-children {
      align-self: flex-start;
      padding-right: 16px;
      border-right: 1px solid $table-border;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }

    &.mobile-show {
      display: none;
      @include xpay-pre-lg {
        display: flex;
      }
    }

    &.desktop-show {
      @include xpay-pre-lg {
        display: none;
      }
    }
  }

  .version-container {
    font-size: 0.75rem;
    padding: 0 24px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: $gray-span;
    justify-content: center;
    width: 100%;
  }

  .sidebar-footer {
    padding: 16px 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 20px;
    color: $gray-span;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0em;

    @include xpay-pre-lg {
      width: 100%;
      padding-top: 28px;
      padding-bottom: 40px;
    }

    .sidebar-item {
      cursor: pointer;
    }
  }

  .navbar-user {
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;
    display: none;

    @include xpay-pre-lg {
      display: flex;
      padding: 16px 24px 16px 24px;
      width: 100%;
      justify-content: space-between;
    }

    .mobile-leftside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 2;
      }

      svg {
        cursor: pointer;
        display: none;
        @include xpay-pre-lg {
          display: block;
        }
      }
    }

    .navbar-profile {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 1;
      }

      .dropdown {
        > span {
          @include xpay-pre-lg {
            font-weight: 500;
          }
        }

        > h4 {
          display: none;
          @include xpay-pre-lg {
            display: block;
            font-size: 14px;
            color: $gray-span;
          }
        }
      }
    }

    .navbar-profile > .avatar {
      background: $primary;
      border-radius: 5px;

      > img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .sidebar-footer > .sidebar-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    font-size: 1rem;
    position: absolute;
    bottom: 24px;
    right: 24px;

    @include xpay-pre-lg {
      position: unset;
      bottom: 0;
      right: 0;
    }

    &:first-child {
      display: none;
    }

    .switch {
      position: relative;
      margin-right: 10px;
      width: 45px;
      height: 24px;
      display: inline-block;

      &.disabled {
        opacity: 0.5;
      }

      > input {
        opacity: 0;
        visibility: hidden;

        &:checked + .slider {
          background: $night-switch-bg;

          &::before {
            transform: translate(18px, -50%);
          }
        }
      }

      .slider {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $c-white;
        border-radius: 34px;
        transition: 0.4s;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          height: 19px;
          width: 19px;
          top: 50%;
          transform: translateY(-50%);
          left: 4px;
          background: $white;
          border-radius: 50%;
          transition: 0.4s;
        }
      }
    }
  }
}

.xpay-logout-drawer {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 16px;

    button {
      height: 52px !important;
    }
  }
}
