@import 'src/styles/references/index';

.xpay-panel-card {
  width: 100%;
  height: auto;
  background: $white;
  padding: 24px;
  border-radius: 20px;
  overflow-y: scroll !important;
  overflow-x: hidden;
  scrollbar-width: none;
  border: 1px solid #D9DFFF;

  @include xpay-pre-lg {
    padding: 16px;
  }

  &.shadowed {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .card-divider {
    grid-area: unset !important;
    width: calc(100% + 48px);
    transform: translateX(24px);

    @include xpay-pre-lg {
      width: calc(100% + 32px);
      transform: translateX(16px);
    }
  }

  display: flex;
  justify-content: start;
  flex-direction: column;

  .head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 24px;

    img {
      height: 34px;
    }

    h3 {
      font-size: 16px;
      color: $black;
      align-self: center;
      margin-bottom: unset;
    }

    a {
      font-weight: 500;
      color: #1552f0;
      font-size: 12px;
      border-radius: 5px;
      background: #FAFBFC;
      padding: 8px 16px;

      &:visited {
        color: #1552f0;
      }
    }

    .xpay-button {
      width: unset;
      max-width: unset;
      margin: unset;
    }

    svg {
      cursor: pointer;
    }
  }

  .card-body {
    width: 95%;
    margin: 24px auto;
    height: 100%;
    position: relative;

    .ant-spin-nested-loading {
      width: 100%;
    }

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &.detail-card::-webkit-scrollbar {
    width: 0px;
  }
}
