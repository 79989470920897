@import 'styles/references/index.scss';

.orders-detail-modal {
  .detail-card {
    @include xpay-pre-lg {
      top: 20px;
      top: calc(50% - 330px);
      left: calc(50% - 40px);

      overflow-y: hidden !important;
    }
  }
}

.xpay-my-orders {
  border-radius: 20px;
  border: 1px solid #D9DFFF;
  background: #FFF;
  max-width: 1066px;
  margin-inline: auto;

  @include xpay-pre-lg {
    margin-top: 120px;
  }

  .type-selection {
    padding: 8px;
    display: flex;
    gap: 8px;
    margin: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #f7f7f7;

    @include xpay-pre-lg {
      display: none !important;
    }

    > div {
      display: flex;
      width: 100%;
      height: 48px;
      color: $black;
      text-align: center;
      padding: 12px auto;
      cursor: pointer;
      transition: 0.5s;
      border-radius: 5px;
      align-items: center;
      justify-content: center;

      &.active {
        color: $white;
        background-color: #1552f0;
      }

      span {
      }
    }
  }

  .orders-divider {
    @include xpay-pre-lg {
      display: none !important;
    }
  }

  .orders-table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    div[role='rowgroup'] {
      border-bottom: 1px solid $table-border !important;
  }

  div[role='row'] {
    height: 69px;
    border-bottom: none !important;
  }

    .xpay-data-table {
      width: auto !important;
    }

    .pagination-footer {
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      > .anticon {
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $table-border;
        height: 40px;
        width: 40px;
        color: $info;
        transition: 0.4s;

        &:hover {
          background: $primary;
          color: $white;
          transition: 0.4s;
        }
      }

      .pages {
        display: flex;
        justify-content: center;
        align-items: center;

        .xpay-button {
          margin-right: 10px;
          button {
            height: 40px;
            width: 40px;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid $table-border !important;

            &.info {
              background: linear-gradient(
                95.43deg,
                $primary -25.7%,
                $access 112.1%
              );
            }
          }
        }
      }
    }
  }
}
