@import 'styles/references/index.scss';

.transaction-order-detail-modal, .transaction-order-detail-drawer {

  .modal-content .head {
    margin-bottom: 24px;

    h4 {
      margin-bottom: 0;
    }

  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .detail-card {
    overflow-y: scroll !important;
    height: 60%;
    border: none;
    padding: unset;

    .card-body {
      width: 100%;
      margin: 0;
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 22px;
      }

      > svg {
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      > .row {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        display: flex;

        img {
          width: 20px;
          height: 20px;
        }

        .long-text {
          text-wrap: nowrap;
          max-width: 290px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        .ant-divider {
          min-width: unset;
          width: unset;
          flex: 1;
          border-color: #D9DFFF;
          border-style: dashed;
          border-width: 1px;
        }

        span.en {
          font-weight: 500 !important;
        }

        > span:first-child {
          color: $gray-span;
          font-size: 16px;

        }

        > span:last-child {
          font-size: 16px;
        }

        > span {
          font-weight: 500;

          span {
            font-weight: 500;
          }
        }

        > div:last-child {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-left: 2px;
          > span.en {
            max-width: 250px;
            overflow-x: hidden;
            text-overflow: ellipsis;

            @include xpay-pre-lg {
              max-width: 128px;
            }
          }

          > span {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .coin-info {
          display: flex;
          gap: 4px;
          align-items: center;
          span {
            font-family: YekanBakh !important;
          }

          img {
            width: 20px;
            height: 20px;
          }

          > span:last-child {
            font-weight: 500;
          }
        }

        .bankaccount {
          img {
            width: 20px;
            height: 20px;
          }

          gap: 1%;
          justify-content: flex-start !important;
          align-items: end;
        }


        svg {
          cursor: pointer;

          path {
          }
        }

      }

      span.success {
        color: $success;
      }

      > .row > span.error {
        color: $danger;
      }

      span.waiting {
        color: $warning;
      }

      //> .row {
      //  flex-direction: row;
      //
      //  > span:first-child {
      //    width: fit-content;
      //  }
      //
      //  > div:last-child,
      //  > span:last-child {
      //    width: fit-content;
      //    display: flex;
      //    justify-content: space-between;
      //    align-items: center;
      //  }
      //}
    }
  }
}
