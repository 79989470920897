.xpay-fishing-modal {
  max-width: 480px;
  box-sizing: border-box;

  .modal-content {
    .video-container {
      width: 100%;
      max-width: 100%;
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
    }

    .video-container video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      border-radius: 8px;
      margin: 0 auto;
      width: 100%;
    }

    p {
      &:first-of-type {
        font-size: 16px;
        font-weight: 500;
      }

      margin-top: 16px;

      &:last-of-type {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
      }
    }

    span {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      gap: 8px;
      margin-top: 16px;
    }

    .xpay-button {
      margin-top: 24px;
      max-width: unset;

      button {
        max-width: unset;

        span {
          margin: unset;
        }
      }
    }
  }
}