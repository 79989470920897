@import 'src/styles/references/index.scss';

.sidebar-item-child {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0 8px;
  width: 100%;

  &.active {
    background-color: #D9DFFF
  }

  &:hover {
    background-color: #D9DFFF
  }

  &.closed {
    width: 87px;
  }

  .content {
    .title {
      color: #7A889B;
      font-size: 14px;
    }

  }
}
