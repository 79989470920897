@import "styles/references/index.scss";

.date-picker-custom {
  display: flex;
  gap: 0.5rem;

  .xpay-dropdown {
    min-width: unset;
    max-width: unset;
    width: 33.3% !important;
  }
}
