@import 'src/styles/references/index.scss';

.action-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  color: $black;
  span {
    font-size: 12px;
    font-weight: 500;
  }
  svg {
    cursor: pointer;
  }
}
