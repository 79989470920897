@import 'src/styles/references/index';

.xpay-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  grid-area: slider;
  max-width: 1002px;
  overflow: hidden;
  padding: 0 20px;

  @include xpay-pre-lg {
    padding: 0;
  }

  .ant-carousel {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .slick-slider {
      width: 100%;
    }

    .slick-list {
      height: 100%;
      border-radius: 10px;
    }

    .slick-dots {
      bottom: 12%;

      li {
        margin-inline: 2px
      }

      li, button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $white;
      }

      .slick-active {
        width: 24px;
        height: 8px;
        background: $white;
        border-radius: 3px;

        button {
          width: 24px;
          height: 8px;
          background: $white;
          border-radius: 3px;
        }
      }

      li {
        button {
          background: $primary-gradient;
        }
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      border-radius: 10px;
      height: 100%;
      max-height: 248px;

      .xpay-slide {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0 auto;
        //padding: 0 5%;
        min-height: 250px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-user-drag: none;
          object-fit: fill;

          @include xpay-pre-lg {
            width: 358px;
            height: 165px;
          }
        }

        @include xpay-pre-lg {
          min-height: 165px;
        }

        .right-side {
          text-align: right;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 65%;
          padding: 16px;
          height: 80%;

          @include xpay-pre-xl {
            width: 48%;
          }

          @include xpay-pre-lg {
            width: 60%;
            padding: 20px 10px;
          }

          h2 {
            font-size: 32px;
            font-style: normal;
            font-weight: 500;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }

          p {
            font-size: 14px;

            @include xpay-pre-lg {
              font-size: 12px;
            }
          }

          h2,
          p {
            text-align: right;
            margin-bottom: 8px;
            color: $white;
          }

          .xpay-button {
            width: 127px;
            height: 45px;
            margin-left: auto !important;
            border: none;
            margin-top: 24px;
            margin-inline: unset;

            button {
              background: $white;
              color: $black;
              transition: background-color 0.3s, color 0.3s;

              @include xpay-pre-lg {
                height: 29px;
                width: 100%;
              }

              span {
                font-size: 14px;
                font-weight: 500;
              }

              &:hover {
                background: $primary;
              }
            }
          }
        }

        img.shape {
          position: absolute;
          top: -4%;
          left: 2.5%;
          height: 108%;

          @include xpay-pre-lg {
            top: 10px;
            left: 10%;
            height: 80%;
          }

          @include xpay-pre-md {
            top: 10px;
            left: 0;
            height: 80%;
          }
        }
      }
    }
  }

  .prev-navigation,
  .next-navigation {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 100;

    @include xpay-pre-lg {
      width: 24px;
      height: 24px;
    }
  }

  .next-navigation {
    right: 0;
    top: calc(50% - 20px);

    @include xpay-pre-lg {
      right: 0;
      top: calc(50% - 12px);
    }
  }

  .prev-navigation {
    left: 0;
    top: calc(50% - 20px);

    @include xpay-pre-lg {
      left: 0;
      top: calc(50% - 12px);
    }
  }
}