@import 'src/styles/references/index.scss';

.xpay-wallet-page {
  max-width: 1066px;
  margin-inline: auto;
  @include xpay-pre-lg {
    padding: 0 12px;
    margin-top: 120px;
  }

  .balance-card {
    justify-content: space-between;
    margin-bottom: 0px !important;
    padding: 0;
    padding-bottom: 40px;

    @include xpay-pre-lg {
      margin-top: 0;
      padding-bottom: unset;
    }

    .head {
      margin-bottom: 0px;
    }

    .card-body {
      display: flex;
      justify-content: space-between;

      @include xpay-pre-lg {
        flex-direction: column;
      }
      .ant-divider {
        height: unset;
      }
      .right-side {
        display: flex;
        flex-direction: column;

        @include xpay-pre-lg {
          flex-wrap: wrap;
          flex-direction: unset;
        }
        h3 {
          font-size: 18px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 16px;
          }
        }
        h4 {
          font-size: 24px;
          font-weight: 500;
          margin-top: 8px;
        }

        .col {
          display: flex;
          flex-direction: column;

          @include xpay-pre-lg {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding-inline: 8px;
          }
        }

        .values {
          display: flex;
          gap: 60px;
          margin-top: 50px;
          justify-content: flex-end;
          flex-wrap: wrap;
          background: $light-blue-secondary;
          border-radius: 10px;
          padding: 16px;

          .col {
            display: flex;
            flex-direction: column;
            gap: 8px;

            span {
              font-size: 14px;
              font-weight: 500;

              @include xpay-pre-lg {
                font-size: 12px;
                white-space: nowrap;
              }
            }
          }

          @include xpay-pre-lg {
            width: 100%;
            flex-wrap: nowrap;
            gap: 8px;
            justify-content: space-between;
            margin-top: 16px;
            background: #FAFBFC;
            border-radius: 10px;
            padding: 8px;
          }
        }
      }
      .middle-side {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .col {
          display: flex;
          flex-direction: column;
          gap: 24px;
          padding-bottom: 16px;

          @include xpay-pre-lg {
            flex-direction: row;
            gap: 16px;
            padding-bottom: unset;
          }

          span {
            font-size: 16px;
            font-weight: 500;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }
        }

        @include xpay-pre-lg {
          margin-top: 16px;
          flex-direction: row;
          justify-content: center;
        }
      }
      .left-side {
        display: flex;
        margin: auto 0;

        @include xpay-pre-lg {
          justify-content: center;
          margin-top: 16px;
        }
      }
    }
  }

  .currencies-card {
    padding: 0;
    margin-top: 32px;

    .card-body {
      margin: 0;
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;

        @include xpay-pre-lg {
          flex-direction: column;
          gap: 16px;
        }

        .right-side {
          width: 30%;

          @include xpay-pre-lg {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px
          }

          .sync-icon-mobile {

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 40px;
            height: 58px;
            border-radius: 5px;
            background: $light-blue-secondary;

            @include xpay-lg {
              display: none;
            }

            svg {
              fill: $primary;
              width: 20px;
              height: 20px;
            }
          }

          .ant-input-affix-wrapper {
          }

          .ant-input {
            display: flex;
            justify-content: space-between;
            border-radius: 10px;
            align-items: center;
            gap: 16px;
            width: 100%;
            height: 48px;

            @include xpay-pre-lg {
              justify-content: unset;
            }

            input {
              width: 100%;
              min-width: 300px;
              padding: 0;
              margin: 0;
              border: none;
              outline: none;
              background-color: transparent;
              box-shadow: none;
              appearance: none;
              font-size: 16px;
              font-weight: 500;
              -webkit-appearance: none;

              &::placeholder {
                font-size: 14px;
                opacity: 0.3;
                font-weight: 500;
              }

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }
          }
        }
        .left-side {
          display: flex;
          gap: 32px;
          align-items: center;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            gap: 8px;
          }


          .ant-statistic {
            span {
              font-size: 24px;
              font-weight: 500;
              letter-spacing: 16px;

              @include xpay-pre-lg {
                font-size: 18px;
                letter-spacing: 4px;
              }
            }
          }
        }

        .sync-icon-desktop {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          background: $light-blue-secondary;

          @include xpay-pre-lg {
            display: none;
          }

          svg {
            fill: $primary;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
