@import 'src/styles/references/index.scss';

.xpay-networks-modal {
  width: 480px;

  .modal-content {
    padding: 0;
    border-radius: 20px;

    > .head {
      display: none;
    }

    .xpay-panel-card {
      border-radius: 20px;

      .card-body {
        margin: unset;
        width: 100%;

        ul {
          list-style: none;
          padding: unset;
          margin: unset;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            padding: 0 10px;
            cursor: pointer;

            &:hover {
              background: linear-gradient(90deg, #FFFFFF -33.94%, #F4F5FF 100%);
              border-radius: 4px;
            }

            span {
              font-size: 16px;
              font-weight: 700;
            }

            .fee {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;

              p {
                font-size: 16px;
                color: #23262F50;
              }

              span {
                font-size: 14px;
              }
            }
          }

          .ant-divider {
            margin: 12px 0;
          }
        }
      }
    }
  }
}

.xpay-assets-modal {
  width: 480px;

  .modal-content {
    padding: 0;
    border-radius: 20px;

    > .head {
      display: none;
    }

    .xpay-panel-card {
      border-radius: 20px;
      max-height: 480px;
      overflow-y: scroll;

      .card-body {
        margin: unset;
        width: 100%;

        .xpay-input {
          height: 64px;
          margin-top: 12px;

          .input-field {
            display: flex;
            border: 1px solid #EBEBEB;
            background: #F7F7F7;

            input {
              font-size: 14px;

            }

            span {
              margin-left: 8px;
            }
          }
        }

        h4 {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 500;
        }

        ul {
          list-style: none;
          padding: unset;
          margin: unset;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            cursor: pointer;

            &:hover {
              background: linear-gradient(90deg, #FFFFFF -33.94%, #F4F5FF 100%);
              border-radius: 4px;
            }

            .currency-title {
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                &:first-of-type {
                  font-size: 14px;
                  font-weight: 500;
                }

                &:last-of-type {
                  font-size: 12px;
                  color: #B3B3B3;
                  font-weight: 500;
                  transform: translateY(-1px)
                }
              }
            }
          }

          .ant-divider {
            margin: 12px 0;
          }
        }
      }
    }
  }
}


.xpay-crypto-withdrawal {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 1066px;

  @include xpay-pre-lg {
    margin: 0 auto;
    margin-top: 128px;
  }

  .withdrawal-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 32px;
    border-radius: 20px;
    border: 1px solid #D9DFFF;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
      padding: 16px;
    }

    .ant-divider {
      @include xpay-pre-lg {
        margin-top: 16px;
      }
    }

    .financial-info {
      display: flex;
      justify-content: space-between;
      border-radius: 10px;

      @include xpay-pre-lg {
        flex-direction: column;
        gap: 16px;
      }

      .info {
        width: 49.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        max-height: 48px;
        border-radius: 5px;
        background-color: $light-blue-secondary;
        gap: 12px;

        @include xpay-pre-lg {
          width: 100%;
          justify-content: space-between;
          padding: 0 16px;
        }

        p {
          font-weight: 500;
          font-size: 16px;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        span {
          font-weight: 500;
          font-size: 16px;
          color: #678AF5;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        &.active {
          cursor: initial;
          background-color: white;
        }
      }

    }

    .xpay-input {
      .input-field {
        display: flex;
        align-items: center;
        padding-left: 10px;
        height: 64px;

        input {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 14px;
          }

          &::placeholder {

          }
        }

        .start-adornment {
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
          margin-right: 10px;

          @include xpay-pre-lg {
            font-size: 14px;
            display: none;
          }
        }

        .adornments {
          display: flex;
          gap: 8px;
          align-items: center;

          .xpay-button {
            @include xpay-pre-lg {
              display: none;
            }

            button {
              background: #5073FB0D;
              white-space: nowrap;

              span {
                font-weight: 500;
                color: #5073FB;
              }
            }
          }


          .currency-container {
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: #F7F7F7;
            padding: 6px;
            border-radius: 5px;
            cursor: pointer;

            span {
              font-size: 16px;
              font-weight: 700;

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .destination-wallet-input {
      margin-top: 16px;

      .input-field {
        input {
          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }
    }

    .memo-input {
      margin-top: 16px;

      .input-field {
        input {
          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }
    }

    .ant-slider {
      margin-top: 32px;
    }

    .choose-network {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      background-color: $light-blue;
      border: 1px solid $light-blue-border;
      border-radius: 10px;
      height: 64px;
      cursor: pointer;
      margin-top: 24px;

      p {
        color: #3F62FE;
        font-size: 16px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .chosen-network {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          font-weight: 700;
          margin-right: 8px;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }
    }

    .withdrawal-info {
      list-style: none;
      background: #FAFAFA;
      border-radius: 5px;
      padding: 8px 16px;
      margin-bottom: unset;
      margin-top: 24px;

      .ant-divider {
        margin: 8px 0;
      }

      .withdrawal-info-item {
        display: flex;
        justify-content: space-between;

        p {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .withdrawal-warning {
      border: 1px solid #CC870066;
      background: #CC87001F;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      margin-top: 16px;

      span {
        font-size: 16px;

        @include xpay-pre-lg {
          font-size: 14px;
        }

        &:first-child {
          color: #E59700;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 4px;

          svg {
            transform: translateY(-1px);
          }
        }
      }
    }

    > .xpay-button {
      max-width: unset;
      margin-top: 32px;
    }
  }

  .alert-collapse {
    border: unset;
    margin-top: 32px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .ant-collapse-item {
      border: unset;

      .ant-collapse-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #CC8700;
        border-radius: 10px;
        background: #FEF6E6;
        padding: 16px;

        @include xpay-pre-lg {
          padding: 12px 8px;
        }

        .ant-collapse-header-text {
          color: #E59700;
          font-weight: 500;
          font-size: 16px;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        &[aria-expanded="true"] {
          border-radius: 10px 10px 0 0;
        }

        .ant-collapse-expand-icon {
          .xpay-button {
            @include xpay-pre-lg {
              width: 96px;
            }

            button {
              background: #E59700 !important;
              height: 32px;

              span {
                @include xpay-pre-lg {
                  font-size: 12px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }

      .ant-collapse-content {
        border: unset;
        background: #FEF6E6;

        .ant-collapse-content-box {
          border-radius: 0px 0px 10px 10px;
          border: 1px solid #CC8700;
          border-top: unset;

          .alert-content {
            display: flex;
            justify-content: flex-start;
            gap: 16px;

            .alert-logo {
              background-color: #F5A200;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 48px;

              svg path {
                fill: $white
              }

              @include xpay-pre-lg {
                min-width: 40px;
              }
            }

            p {
              font-size: 14px;
              font-weight: 500;

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

  }

  .xpay-faq {
    margin-top: 32px;
    border: 1px solid #D9DFFF;
    border-radius: 20px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .head {
      h3 {
        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }
  }

}