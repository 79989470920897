@import 'styles/references/index.scss';

.xpay-dashboard-referral {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 100px;
  margin-inline: auto;
  max-width: 1066px;

  @include xpay-pre-lg {
    margin-bottom: 120px;
    padding: 0 0;
  }

  .xpay-panel-card {
    .head {
      .info-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 32px;
        background-color: #fafbfc;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .referral-analytics-card {
    padding: 16px;
    margin: 0 auto;

    @include xpay-pre-lg {
      width: 90%;
    }

    .card-body {
      display: flex;
      justify-content: space-between;
      gap: 32px;

      @include xpay-pre-lg {
        flex-wrap: wrap;
        gap: 16px;
      }

      .box {
        display: flex;
        flex-direction: column;
        height: 206px;
        border-radius: 10px;
        max-width: 185px;
        width: 100%;
        justify-content: space-between;
        position: relative;
        overflow: hidden;

        &.info {
          gap: 24px;
          padding: 24px 16px;
          background: linear-gradient(180deg, #e4eaff 0%, #fbfcff 100%);

          @include xpay-pre-lg {
            width: calc(50% - 16px);
          }
        }

        .mask {
          width: 100%;
          opacity: 0.1;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 33%);
          height: 100%;
          z-index: 1;
        }

        p {
          z-index: 2;
          display: flex;
          flex-direction: column;
          gap: 16px;
          span {
            font-weight: 800;
            white-space: nowrap;

            &.title {
              font-size: 16px;

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }

            &.value {
              font-size: 20px;

              @include xpay-pre-lg {
                font-size: 16px;
              }
            }
          }
        }

        &:nth-child(1) {
          background: linear-gradient(0deg, rgba(0, 128, 255, 0.33) 0%, rgba(255, 255, 255, 1) 100%);

          span.title {
            color: #0080FF;
          }
        }

        &:nth-child(2) {
          background: linear-gradient(0deg, rgba(178, 54, 54, 0.33) 0%, rgba(255, 255, 255, 1) 100%);

          span.title {
            color: #B23636;
          }
        }

        &:nth-child(3) {
          background: linear-gradient(0deg, rgba(0, 178, 104, 0.33) 0%, rgba(255, 255, 255, 1) 100%);

          span.title {
            color: #00B268;
          }
        }

        &:nth-child(4) {
          background: linear-gradient(0deg, rgba(157, 60, 255, 0.33) 0%, rgba(255, 255, 255, 1) 100%);

          span.title {
            color: #9D3CFF;
          }
        }

        &.balance {
          justify-content: space-between;

          @include xpay-pre-lg {
            width: 100%;
            max-width: unset;
          }
        }

        .balance {
          display: flex;
          flex-direction: column;
          background-color: #fafbfc;
          border: 1px solid #d9dfff;
          border-radius: 10px;
          padding: 24px 16px;
          gap: 24px;
        }
      }

      .xpay-button {
        max-width: unset;

        button {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .referral-invite-friends-card {
    margin-top: 32px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .card-body {
      display: flex;
      margin: 24px auto;
      justify-content: space-between;
      gap: 24px;

      @include xpay-pre-lg {
        flex-direction: column;
      }

      .referral-banner {
        background: linear-gradient(180deg, #e4eaff 0%, #fbfcff 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 293px;
        width: 40%;

        @include xpay-pre-lg {
          width: 100%;
          max-height: 210px;

          img {
            max-width: 231px;
          }
        }
      }

      .referral-content {
        display: flex;
        flex-direction: column;
        width: 60%;

        @include xpay-pre-lg {
          width: 100%;
        }

        h3 {
          font-size: 16px;
          font-weight: 800;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        p {
          color: #859abc;
          font-size: 14px;
          font-weight: 500;
          margin-top: 16px;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        .invitation {
          border-radius: 5px;
          border: 1px solid #d9dfff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          margin-top: 24px;

          span {
            font-size: 14px;
            font-weight: 800;
            color: #a8a9aa;
          }

          a {
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;

            @include xpay-pre-lg {
              font-size: 12px;
            }
          }

          svg {
            cursor: pointer;
            min-width: 32px;
            min-height: 32px;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 24px;

          .xpay-button {
            max-width: unset;

            button {
              width: 100%;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .referral-guide-card {

    @include xpay-pre-lg {
      display: none;
    }

    margin-top: 32px;

    .card-body {
      .steps {
        margin-inline: auto;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;

        .step {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          min-width: 28px;
          min-height: 28px;
          max-width: 28px;
          max-height: 28px;
          background: $primary;
          font-weight: 800;
          color: white;
        }

        span {
          display: flex;
          height: 1px;
          width: 20%;
          border: 1px dashed #3f62fe;
        }
      }

      .titles {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        h4 {
          width: 20%;
          text-align: center;
        }
      }

      .descriptions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: #859abc;
          font-size: 14px;
          font-weight: 500;
          width: 20%;
          text-align: center;
        }
      }
    }
  }

  .referral-guide-card-mobile {

    @include xpay-lg {
      display: none;
    }

    width: 90%;
    margin-top: 32px;
    margin-inline: auto;

    .card-body {
      .steps {
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        gap: 24px;

        .step-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .step {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            min-width: 28px;
            min-height: 28px;
            max-width: 28px;
            max-height: 28px;
            background: $primary;
            font-weight: 800;
            color: white;
          }

          h4 {
            width: 100%;
            text-align: center;
            margin-top: 16px;
            font-size: 16px;
          }

          p {
            color: #859abc;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}


.xpay-modal.referral-invitation-modal {
  max-width: unset;
  min-width: unset;

  .modal-content {
    border-radius: 20px;
    width: 420px;

    .head {
      margin-bottom: 24px;
    }

    > .description {
      display: none;
    }

    .content-description {
      border-radius: 10px;
      background-color: #F2F2F2;
      padding: 16px;
      border: 1px solid #EBEBEB;

      p {
        width: 100%;
        text-align: right;
        margin: 0 auto;
        font-size: 14px;
        color: $bold-span;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .xpay-button:first-child {
        margin-right: 0px;
      }

      margin-top: 24px;

      .xpay-button {
        margin-right: 0;

        &:last-child {
          margin-right: 16px;

          button {
            width: 100%;
          }
        }

        button {
          height: 48px;

          &.info {
            background: linear-gradient(
                            95.43deg,
                            $primary -25.7%,
                            $access 112.1%
            );
          }
        }
      }
    }
  }
}

.referral-withdrawal-modal {
  .referral-withdrawal {
    display: flex;
    width: auto;
    height: auto;
    max-width: 430px;
    border-radius: unset;
    padding: unset;
    overflow-y: visible;
    border: unset;

    .input-info-label {
      width: 100%;

      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-body {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      > .xpay-button {
        margin-top: 24px;
      }
    }
  }
}

.xpay-modal.referral-ads-modal {
  max-width: unset;
  min-width: unset;

  .modal-content {
    border-radius: 20px;
    width: 420px;

    > .description {
      display: none;
    }

    > .head {
      margin-bottom: 24px;

      .close {
        cursor: pointer;
      }
    }

    > .invitation {
      border-radius: 5px;
      border: 1px solid #d9dfff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin-top: 24px;
      gap: 8px;

      span {
        font-size: 14px;
        font-weight: 800;
        color: #a8a9aa;
        white-space: nowrap;
      }

      a {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      svg {
        cursor: pointer;
        min-width: 32px;
        min-height: 32px;
      }
    }

    .ad-image {
      position: relative;
      display: flex;
      justify-content: center;

      > img {
        border-radius: 10px;

        @include xpay-pre-lg {
          width: 100%;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        width: 50%;
        display: flex;

        .xpay-button {
          margin-right: 0px;
          width: 100%;
          margin: 0;

          button {
            width: 100%;
          }
        }
      }

      > .xpay-button:first-child {
        margin-right: 0px;
      }

      margin-top: 24px;

      > .xpay-button {
        margin-right: 0;

        &:last-child {
          width: 100%;
        }

        button {
          height: 48px;
          width: 100%;

          &.info {
            background: linear-gradient(
                            95.43deg,
                            $primary -25.7%,
                            $access 112.1%
            );
          }
        }
      }
    }

  }

}
