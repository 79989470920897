@import 'src/styles/references/index.scss';

.xpay-markets-dashboard {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  column-gap: 5px !important;
  border-radius: 20px !important;
  padding: 24px 0 !important;
  height: auto !important;
  max-width: 1066px;
  margin-inline: auto;

  .card-body {
    width: 100%;
  }

  @include xpay-pre-lg {
    width: 100%;
    padding: 0 24px !important;
    margin-top: 120px;
  }

  // @include xpay-pre-md {
  //   width: 100%;
  //   padding: 0;
  //   margin: 0 auto;
  // }

  .desktop-show,
  .mobile-show {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    @include xpay-pre-lg {
      display: none;
    }

    .filter-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;

      @include xpay-pre-lg {
        flex-direction: column;
        background: $white;
      }

      > .ant-divider {
        @include xpay-pre-lg {
          display: none;
        }
      }

      .buttons,
      .fields {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .buttons {
        display: flex;
        gap: 16px;
        .search {
          max-width: unset;
          width: unset;
          button {
            width: 104px;
            height: 48px;
            border-radius: 5px;
            background: #36b289;
            border: none;

            span {
              color: white;
              font-size: 14px;
              font-weight: 500;
            }

            @include xpay-pre-lg {
              max-width: unset;
            }
          }
        }

        .clear-search {
          max-width: unset;
          width: unset;
          button {
            width: 104px;
            height: 48px;
            border-radius: 5px;
            border: 1px solid #b23636;

            span {
              color: #b23636;
              font-size: 14px;
              font-weight: 500;
            }

            @include xpay-pre-lg {
              max-width: unset;
            }

            &:hover {
              background: #b23636;
              span {
                color: white;
              }
            }
          }
        }
      }

      .fields {
        width: 100%;
        justify-content: space-between;
        .right-side {
          display: flex;
          width: fit-content;
          gap: 16px;
          @include xpay-pre-lg {
            flex-direction: column;
          }

          .xpay-button {
            width: 100%;
            max-width: unset;
          }
        }

        .field {
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          border: 1px solid #ebebeb;
          background: #fcfcfc;
          padding: 16px;
          align-items: center;
          gap: 16px;
          height: 48px;
          width: fit-content;

          @include xpay-pre-lg {
            width: 100%;
            justify-content: unset;
          }

          input {
            width: max-content;
            min-width: 300px;
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            background-color: transparent;
            box-shadow: none;
            appearance: none;
            font-size: 16px;
            font-weight: 500;
            -webkit-appearance: none;
            color: $black;

            &::placeholder {
              font-size: 14px;
              opacity: 0.3;
              font-weight: 500;
            }

            @include xpay-pre-lg {
              font-size: 14px;
              min-width: fit-content;
              width: 100%;
            }
          }
        }

        input {
          height: 38px !important;
        }

        > div {
          // min-width: 130px;
          width: 100%;
          color: $info;
          border-radius: 10px;

          &.buttons {
            width: fit-content;
          }

          &.transaction-state {
            min-width: 200px;
          }

          @include xpay-pre-lg {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 16px;

            img {
              width: 25px;
              height: 25px;
              margin-left: 8px;
            }
          }

          &.states-dropdown {
            min-width: max-content;
          }
        }

        &:first-child {
          @include xpay-pre-lg {
            margin-top: 16px;
          }
        }

        .ant-dropdown-trigger {
          width: fit-content;
          button {
            border: 1px solid $table-border !important;
            height: 48px;

            svg {
              fill: $primary;
            }

            &:hover {
              background-color: $primary;

              svg {
                fill: $white;
              }
            }

            > span {
              color: black;
              font-weight: 500;
            }
          }

          &.ant-dropdown-open,
          &:hover {
            button > span:first-child {
              color: $white;
            }
          }
        }

        .xpay-calendar {
          cursor: pointer;
          width: 117px;
          height: 48px;

          .xpay-input {
            .input-field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;
              background: $white;
              height: 48px;

              input {
                cursor: pointer;
                font-weight: 500;
                &::placeholder {
                  color: black;
                }
              }

              svg {
                path {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-show {
    display: none;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 42px;

    > .xpay-input {
      margin-bottom: 16px;
      width: 100%;
      max-width: 425px;

      .input-field {
        border-radius: 5px;
        background: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .anticon {
          margin: 12px 14px;
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
    > .xpay-dropdown {
      margin-bottom: 16px;
      width: 100%;
      max-width: 425px;
      border-radius: 5px;

      .dropdown-selector {
        width: 100%;
        justify-content: space-between;
        padding: 14px 12px;
      }

      .dropdown-items {
        max-width: unset;
      }
    }

    > .coin-card {
      margin-bottom: 24px;
      background: $white;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $table-border;
      border-radius: 20px;
      max-width: 425px;

      @include xpay-pre-lg {
        .recharts-responsive-container {
          width: 100px !important;
        }
      }

      .card-body {
        width: 100%;
        margin: 0;
        padding: 0;
        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        }

        > div:nth-child(1),
        > div:nth-child(2),
        > div:nth-child(3) {
          .left {
            font-weight: 500;
          }
        }

        > div:nth-child(4) {
          .right {
            > p {
              margin-left: 4px;
              span {
                margin-left: 4px;
                font-size: 10px;
              }
            }
            > span {
              margin-left: 4px;
              text-align: left;
              direction: ltr;
              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }
          }
        }

        &:first-child {
          .right {
            justify-content: flex-start;
            align-items: center;

            img,
            p,
            span {
              margin-left: 8px;
            }

            img {
              width: 25px;
              height: 25px;
            }

            p {
              font-size: 12px;
              font-weight: 500;
            }

            span {
              color: $gray-text;
              font-size: 12px;
            }
          }

          .left {
            justify-content: flex-start;
            align-items: center;

            p {
              font-size: 12px;
            }
          }
        }

        &:nth-child(4) {
          .right {
            p {
              span {
                margin-right: 4px;
                margin-left: 4px;
                font-size: 10px;
                font-family: 'Montserrat';
              }
            }
            > span {
              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }
          }
        }

        .right,
        .left {
          display: flex;
          width: max-content;
        }
      }

      .xpay-button {
        max-width: unset;
        margin: 0;
        width: 1005;
      }
    }

    @include xpay-pre-lg {
      display: flex;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-thumb-bg;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }
  .price-chart-table {
    width: 100%;
    margin: 0 auto;
    margin-top: 24px;
    border-top: 1px solid $table-border;

    div[role='rowgroup'] {
      border-bottom: 1px solid $table-border !important;
    }

    div[role='row'] {
      height: 69px;
      border-bottom: none !important;

      &:hover {
        background: rgba(217, 223, 255, 0.2);
      }
      div[role='gridcell']:nth-child(4) {
        padding: 0px !important;

        .recharts-surface {
          width: 120px !important;
        }
      }
      div[role='cell'],
      div[role='columnheader'] {
        justify-content: center;
        font-weight: 500;
        > div {
          overflow: unset !important;
        }
      }
    }

    .rdt_Pagination {
      display: none;
    }
    div[role='gridcell'] {
      height: 100%;
      min-width: max-content !important;

      .xpay-button {
        width: 107px;
        border-radius: 5px;
        button {
          height: 35px;
          width: max-content;
        }
      }
    }
    div[data-column-id='last_usdt_price'] {
      @include xpay-pre-lg {
        justify-content: end !important;
      }
    }
    div[data-column-id='segmented'] {
      justify-content: center;
    }
    div[data-column-id='name'] {
      justify-content: flex-start !important;
    }
    div[data-column-id='chart'] {
      display: flex;
      justify-content: center !important;
      // margin-left: 16px;
      padding-right: unset !important;
      white-space: nowrap;
      // padding-left: unset !important;
    }
    div[data-column-id='create-order'] {
      justify-content: center !important;
      // padding-right: unset !important;

      .currency-buttons {
        display: flex;
        gap: 16px;

        .xpay-button {
          button {
            margin: auto;
            border-radius: 8px;
            height: 32px;
            min-width: unset;
          }
        }

        .buy {
          button {
            border-color: #36b289;

            &:hover {
              span {
                color: $white;
              }
              background: #36b289;
            }

            span {
              color: #36b289;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .sell {
          button {
            border-color: #b23636;

            &:hover {
              span {
                color: $white;
              }
              background: #b23636;
            }

            span {
              color: #b23636;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    div[role='columnheader'] div {
      color: $gray-text;
      white-space: nowrap;
    }

    .xpay-badge {
      width: max-content;
      border-radius: 10px;
    }
    img {
      height: 32px !important;
      margin-left: 6px;
    }
    p.market {
      font-weight: 500;
      display: flex;
      align-items: center;
      width: max-content;
      span.symbol {
        font-family: Roboto !important;
        font-size: 11px;
        margin-right: 4px;
        opacity: 0.6;
      }
    }
  }
}
