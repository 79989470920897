@import 'styles/references/index.scss';

.xpay-popup {
  position: absolute;
  transform: translate(-50%, -50%);
  left: calc(50%);
  top: 50%;
  z-index: 1020;

  @include xpay-pre-lg {
    left: 50%;
    top: 50%;
  }

  @include xpay-pre-sm {
    left: 47%;
    top: 50%;
  }

  .xpay-button {
    max-width: unset !important;
  }

  .xpay-panel-card {
    padding: 0px !important;
  }

  .popup-content {
    background: $white;
    padding: 24px 16px;
    border-radius: 16px;
    width: 328px;

    @include xpay-pre-lg {
      min-width: 324px;
    }

    > img {
      width: 100%;
    }

    @include xpay-pre-lg {
      max-width: 100% !important;
    }

    h4 {
      text-align: right;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      margin-top: 13px;
    }

    .xpay-input {
      width: 100%;
    }

    p {
      width: 100%;
      text-align: right;
      margin: 0;
      color: $gray-span;
      font-size: 14px;
      margin-top: 24px;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      .xpay-button:first-child {
        margin-right: 0px;
      }

      .xpay-button {
        max-width: 130px;

        &:last-child {
          margin-right: 16px;
        }

        button {
          height: 40px;

          &.outlined {
            border: 1px solid $gray-span !important;
            color: $gray-span;

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
