@import "../../../../../styles/references/index.scss";

@import "../../../../../styles/references/index.scss";

.order-details-modal {
  @include xpay-pre-lg {
    width: 90%;
  }
}

.order-details-modal .modal-content {
  padding: 0;
  width: 480px;
  border-radius: 20px;

  > .head {
    display: none;
  }

  .xpay-panel-card {
    .card-body {
      width: 100%;
      margin: unset;

      .order-details {
        margin: unset;
        padding: unset;
        list-style: none;
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 16px;

        @include xpay-pre-lg {
          grid-template-columns: 1fr 1fr;
        }

        li {
          display: flex;
          flex-direction: column;
          gap: 8px;

          span {
            &:first-child {
              color: #859ABC;
              font-weight: 500;
              font-size: 16px;
            }

            &:last-child {
              font-size: 16px;
              font-weight: 500;

              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }

      .xpay-alert .alert-box .alert-right-side .alert-icon {
        padding: 0 4px;
      }

      .xpay-alert {
        margin-top: 16px;
      }

      .buttons {
        margin-top: 32px;
        display: flex;
        gap: 16px;

        .xpay-button {
          width: 50%;
          max-width: unset;

          button {
            max-width: unset;
            height: 48px;
            width: 100%;
          }
        }
      }
    }
  }
}