@import 'styles/references/index.scss';

.xpay-dashboard-profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 27px;
  margin-bottom: 70px;
  margin-inline: auto;
  max-width: 1066px;

  @include xpay-pre-lg {
    padding: 0 24px;
    margin: 128px 0;
  }

  .profile-card {
    padding: 0;
    height: 312px;

    @include xpay-pre-lg {
      height: unset;
    }

    .head {
      margin: 24px;
    }
    .card-body {
      display: flex;
      margin: 0;
      width: 100%;

      @include xpay-pre-lg {
        flex-direction: column;
        margin: 24px auto;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding-inline: 32px;
        border-left: 1px solid #d9dfff;

        @include xpay-pre-lg {
          width: 100%;
          border-left: unset;
        }

        .stepper {
          display: flex;
          align-items: center;
          width: 100%;
          height: 110px;
          background-color: #fafbfc;
          border-radius: 10px;
          padding: 12px 16px;

          .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .circle {
              margin: 0 auto;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #d9dfff;
              color: #859abc;
              text-align: right;
              font-size: 16px;
              font-weight: 500;
            }

            .text {
              margin-top: 8px;
              font-size: 12px;
              font-weight: 500;
              color: #859abc;
              text-align: right;
            }
          }

          .line {
            flex: 1;
            height: 2px;
            background-color: #ccc;
            transform: translateY(-10px);

            &.active {
              background-color: #1552F0;
            }
          }

          .step.active {
            .circle {
              background-color: #1552F0;
              color: #fff;
              opacity: 1;
            }

            .text {
              color: #112a5d;
            }
          }
        }
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 32px;
        width: 50%;

        @include xpay-pre-lg {
          width: 100%;
          margin-top: 32px;
        }

        .col {
          display: flex;
          flex-direction: column;
          gap: 16px;

          div {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }
          }
        }

        .ant-progress {
          filter: drop-shadow(
              0px 11.52px 25.4815px rgba(97, 132, 247, 0.151852)
            )
            drop-shadow(0px 8.14815px 6.51852px rgba(97, 132, 247, 0.0981481));

          .percent-info {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transform: translateX(-48%);

            span:first-child {
              display: inline-block;
              font-weight: 700;
              color: #1552f0;

              &.success {
                color: $success;
              }

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }

            span:last-child {
              &.success {
                color: $success;
              }

              color: black;
              margin-top: 5px;
              font-size: 14px;

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .xpay-panel-card:not(:last-child),
  .xpay-panel-card.user-level {
    margin-bottom: 0px !important;
  }

  .info-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 550px;

    @include xpay-pre-lg {
      flex-direction: column;
      height: auto;
    }

    h4 {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
    }

    .user-info {
      margin-left: 15px;
      width: 50%;
      height: 100%;
      border-radius: 20px;
      padding: 20px 10px 10px;

      @include xpay-pre-lg {
        margin: 0;
        width: 100%;
      }

      .content {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .static {
          p {
            justify-content: start !important;
          }
        }
      }

      .static,
      .dynamic {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 20px;

        p {
          width: 100%;
          display: flex;
          align-items: center;
          min-height: 40px;
          font-size: 14px;
          justify-content: end;
        }
      }

      .static {
        p {
          color: $gray-text;
        }
      }

      .dynamic {
        margin-right: 50px;
        @include xpay-pre-lg {
          margin-right: 0px !important;
        }
        p {
          font-weight: 500;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .xpay-button {
            max-width: 90px;

            button {
              border: 1px solid $table-border !important;
            }
          }
        }
      }
    }

    .user-level {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 20px;
      margin-right: 15px;
      padding: 10px;

      @include xpay-pre-lg {
        width: 100%;
        margin-right: 0;
        margin-top: 32px;
      }

      .level,
      .slider {
        min-height: 200px;
      }

      .level {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        > div {
          height: 100%;
        }

        > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          min-height: 200px;

          .status {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > span {
              display: block;
              width: 8px;
              height: 8px;
              background: linear-gradient(
                95.43deg,
                $info -25.7%,
                $access 112.1%
              );
              border-radius: 50%;
            }

            p {
              margin-right: 8px;
            }
          }

          .xpay-button {
            margin-top: 50px;

            button {
              height: 30px;
              font-size: 14px;
              background: linear-gradient(
                95.43deg,
                $primary -25.7%,
                $access 112.1%
              );
            }
          }
        }

        > div:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 200px;
        }
      }

      .slider {
        .xpay-slider {
          height: 100%;
          width: 100%;

          .swiper {
            padding: 0;
            width: 100%;

            .xpay-slide {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              padding: 20px;

              .right-side {
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                width: 60%;

                h2 {
                  font-size: 18px;
                }

                .xpay-button {
                  max-width: 100px;
                  height: 38px;

                  button.outlined {
                    border: 0px !important;
                  }
                }
              }

              img {
                top: 0%;
                align-self: center;
                left: 0;
                width: 35%;
                height: auto;

                @include xpay-pre-lg {
                  width: 30%;
                  top: -15%;
                }
                @include xpay-pre-md {
                  width: 50%;
                  top: 10%;
                }
              }
            }
          }
        }
      }
    }
  }

  .content {
    column-gap: 6px;
    .email {
      font-family: Roboto !important;
    }
  }

  .security-card {
    border-radius: 20px;
    padding: 0;

    .card-body {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      // column-gap: 48px;

      @include xpay-pre-lg {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        padding: 24px;
        margin-bottom: 0;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .security,
      .two-step {
        width: 100%;

        h4 {
          border-bottom: 1px solid #f0f0f0;
          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        @include xpay-pre-lg {
          width: 100%;
        }
      }

      .two-step .content {
        border-right: 1px solid #f0f0f0;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dynamic {
          align-items: flex-end;

          .xpay-button {
            margin: unset;


            button {
              border: 1px solid $table-border !important;
            }
          }
        }
      }

      .security .content {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #859abc;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .static,
      .dynamic {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 20px;

        p,
        > div {
          width: 100%;
          display: flex;
          align-items: center;
          min-height: 40px;
          font-size: 14px;
        }
      }

      .two-step .content .static > div {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        p,
        span {
          text-align: start;
        }

        p {
          color: black;
          font-weight: 500;
          font-size: 14px;
        }

        span {
          font-size: 12px;
          color: #a8a9aa;
          font-weight: 500;
        }
      }

      .static {
        p {
          color: $gray-text;
        }
      }

      .dynamic {
        p {
          font-weight: 500;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .xpay-button {
            max-width: 90px;

            button {
              border: 1px solid $table-border !important;
            }
          }
        }
      }

      .two-step .static,
      .two-step .dynamic {
        justify-content: flex-start;

        > button,
        .xpay-button {
          margin: 15px 0;
        }
      }

      .security .dynamic {
        width: 100%;
        margin-right: 100px;
        row-gap: 20px;

        @include xpay-pre-lg {
          margin-right: 16px;
        }

        .password {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          button {
            width: 96px;
          }
        }
      }

      .ant-divider {
        min-height: 200px;

        &.vertical {
          @include xpay-pre-lg {
            display: none;
          }
        }

        &.horizontal {
          display: none;
          min-height: unset;
          @include xpay-pre-lg {
            display: block;
          }
        }
      }
    }
  }

  .sessions-row {
    border-radius: 20px;

    h4 {
      font-size: 16px;
      font-weight: 500;
    }

    .card-body > .desktop-show {
      @include xpay-pre-lg {
        display: none;
      }

      .ant-table {
        margin-top: 25px;

        th.ant-table-cell {
          color: $gray-span;
        }

        .device-model-column {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > span:first-child {
            color: $gray-span;
          }

          p {
            color: $gray-span;
            margin-right: 5px;
          }
        }

        .action-column {
          .device-logout-btn,
          .device-logout-icon {
            color: $orange;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .card-body > .mobile-show {
      display: none;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      border: 1px solid $table-border;
      border-radius: 10px;

      @include xpay-pre-lg {
        display: flex;
        padding: 0;
        margin-top: 16px;
      }

      > li {
        background: $white;
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        border: 1px solid $table-border;
        border-left: 0;
        border-right: 0;
        max-width: unset;
        height: 160px;

        &:not(li:last-child) {
          border-bottom: 0px;
        }

        &:first-child {
          border-top: 0px;
        }

        > .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          .right,
          .left {
            display: flex;
            width: max-content;
          }
        }

        > .row:first-child {
          .right {
            justify-content: flex-start;
            align-items: center;

            > span {
              .device-model-column {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }

            img,
            p,
            span {
              margin-left: 8px;
            }

            img {
              width: 25px;
              height: 25px;
            }

            p {
              font-size: 12px;
              font-weight: 500;
            }

            span {
            }
          }

          .left {
            justify-content: flex-start;
            align-items: center;

            p {
              margin-left: 3px;
              color: $danger;
            }

            span {
              color: $danger;
            }
          }
        }

        > .row:nth-child(2) {
          .left,
          .right {
            p,
            span {
              font-size: 12px;
            }

            p:last-child {
              margin-right: 3px;
            }

            span {
              font-weight: 500;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
