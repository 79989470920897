@import 'styles/references/index.scss';

.xpay-notification {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  cursor: pointer;

  @include xpay-pre-lg {
    order: 2;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;

    @include xpay-pre-lg {
      width: 30px;
      height: 30px;
    }
  }

  .badge {
    position: absolute;
    display: flex;
    top: 2px;
    right: 5px;
    width: 15px;
    height: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: $danger;
    color: $white;
    border-radius: 50%;
    font-size: 10px;

    @include xpay-pre-lg {
      width: 10px;
      font-size: 8px;
      height: 10px;
    }
  }
  > svg {
    vertical-align: middle;
    margin: auto;
    width: 25px;
    height: 25px;

    @include xpay-pre-lg {
      width: 20px;
      height: 20px;
    }
  }

  .notification-box {
    position: absolute;
    width: 434px;
    top: 85px;
    left: 64px;
    border-radius: 10px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: start;
    filter: drop-shadow(
        0px 1.8529177904129028px 3.1499602794647217px rgba(93, 128, 248, 0.01)
      )
      drop-shadow(
        0px 8.152839660644531px 6.522270679473877px rgba(93, 128, 248, 0.02)
      )
      drop-shadow(
        0px 20.011512756347656px 13.007484436035156px rgba(93, 128, 248, 0.03)
      )
      drop-shadow(
        0px 38.54069137573242px 25.49614906311035px rgba(93, 128, 248, 0.03)
      )
      drop-shadow(
        0px 64.85211944580078px 46.87881851196289px rgba(93, 128, 248, 0.04)
      )
      drop-shadow(
        0px 100.05756378173828px 80.04605102539062px rgba(93, 128, 248, 0.05)
      );

    .notifications-card {
      padding: unset;
      border-radius: 10px 10px 0 0;
      border-bottom: unset;
      .head {
        padding: 16px;
        margin-bottom: unset;
      }
      .card-body {
        width: 100%;
        margin: unset;
        justify-content: center;
        align-items: center;
      }
    }

    @include xpay-pre-lg {
      width: 300px;
      top: 72px;
      left: 24px;
      overflow: scroll;
      max-height: 480px;
    }

    .polygan {
      position: absolute;
      top: -10px;
      left: calc(50% - 8px);
      align-self: center;

      @include xpay-pre-lg {
        left: 8px;
      }
    }

    .notification-item {
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      position: relative;

      &:nth-child(2) {
        border-radius: 10px 10px 0 0;
      }

      .divider {
        display: inline-block;
        height: 1px;
        width: 100%;
        background: $table-border;
        position: absolute;
        top: 0;
        left: 0;
      }
      .notification-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: nowrap;

        p,
        span {
          font-size: 14px;
          font-weight: 500;
        }
        .date {
          font-weight: 500 !important;
          color: #0086FF;
        }
        .notification-title {
          display: flex;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;
          }

          span {
            margin-right: 8px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      > p {
        margin-top: 8px;
        font-size: 12px;
        text-align: right;
        color: #85A2BC;
      }
    }

    .notification-item.read {
      background: $read-notification;
      .notification-head {
        svg {
          display: none;
        }
      }
    }

    .footer {
      padding: 16px;
      width: 100%;
      position: relative;

      .divider {
        display: inline-block;
        height: 1px;
        width: 100%;
        background: $table-border;
        position: absolute;
        top: 0;
        left: 0;
      }

      .xpay-button {
        width: 100%;
        max-width: unset;
        button {
          width: 100%;
          margin: auto;
          height: 42px;
          border-radius: 10px;
          background: linear-gradient(
            95.43deg,
            $primary -25.7%,
            $access 112.1%
          );
        }
      }
    }
  }
}
