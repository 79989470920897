@import 'styles/references/index.scss';

.popular-currencies-box {
  grid-area: currencies;
  border-radius: 10px;
  border: 1px solid #d9dfff;
  background: #fff;

  .card-body {
    width: 100%;
    margin: unset;
  }

  .currency {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;

    p {
      display: flex;
      gap: 4px;
      white-space: nowrap;

      .symbol {
        color: #b3b3b3;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  [data-column-id='name'] {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  [data-column-id='usdtPrice'] {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  [data-column-id='buyPrice'] {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  [data-column-id='sellPrice'] {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  [data-column-id='exchange'] {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    .currency-buttons {
      display: flex;
      gap: 16px;

      .xpay-button {
        button {
          margin: auto;
          border-radius: 8px;
          height: 42px;
          min-width: 73px;
        }
      }

      .buy {
        button {
          border-color: #36b289;

          &:hover {
            span {
              color: $white;
            }
            background: #36b289;
          }

          span {
            color: #36b289;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .sell {
        button {
          border-color: #b23636;

          &:hover {
            span {
              color: $white;
            }
            background: #b23636;
          }

          span {
            color: #b23636;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.popular-currencies-slider {
  overflow-x: hidden;
  height: max-content;
  border: 1px solid #d9dfff;
  width: 100%;

  .popular-currencies-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;

    .name-symbol {
      display: flex;
      align-items: center;
      gap: 8px;

      h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: unset;
      }
    }

    .symbol {
      color: #96a0b5;
      font-size: 12px;
      text-align: end;
      align-self: end;
    }

    .buy,
    .sell {
      display: flex;
      flex-direction: column;

      p {
        color: #96a0b5;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .sell {
      align-items: end;
    }

    .xpay-button {
      button {
        margin: auto;
        border-radius: 8px;
        height: 42px;
        min-width: 73px;
      }
    }

    .buy-button {
      button {
        border-color: #36b289;
        width: 90%;

        &:hover {
          span {
            color: $white;
          }
          background: #36b289;
        }

        span {
          color: #36b289;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .sell-button {
      button {
        border-color: #b23636;
        width: 90%;

        &:hover {
          span {
            color: $white;
          }
          background: #b23636;
        }

        span {
          color: #b23636;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .pre-navigation {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 10px;
    left: -24px;
    top: -37px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .next-navigation {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 10px;
    cursor: pointer;
    right: -24px;
    top: -37px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
}
