@import 'src/styles/references/index';

.xpay-wallet-navigation-item {
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 0px;
  padding: 0px 24px;
  height: 56px;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  justify-content: space-between;

  .content {
    display: flex;
    gap: 16px;
    align-items: center;

    p {
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      color: #859ABC;
    }
  }

  &.active {
    justify-content: space-between;
    border-radius: 10px;
    background: linear-gradient(95deg, #3f62fe -25.7%, #678af5 112.1%);

    .content {
      p {
        color: $white;
      }

      svg {
        path {

          stroke: $white;
        }
      }
    }
  }
}
