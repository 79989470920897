@import 'src/styles/references/index.scss';

.xpay-modal.logout-modal {
  .modal-content {
    > .head {
      display: none;
    }

    .confirm-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 100px;
      min-width: max-content;

      > .head {
        justify-content: center;
      }
    }
  }
}

.xpay-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% + 32px);
  // padding-top: 32px;
  column-gap: 32px;
  z-index: 1000;
  border-bottom: 1px solid d9dfff;

  @include xpay-pre-lg {
    padding: 8px 16px;
    background-color: $bg;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0px auto;
    z-index: 999;
  }

  .box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 16px;
    width: 100%;
    margin: auto auto;
    border-bottom: 1px solid #d9dfff;
    // border-radius: 20px;
    @include xpay-pre-lg {
      border-radius: 20px;
    }
  }

  &.smaller {
    .space {
      min-width: 255px;
      transition: min-width 0.3s;
    }
  }

  &.bigger {
    .space {
      min-width: 151px;
      transition: min-width 0.3s;
    }
  }

  .navbar-welcome {
    @include xpay-pre-lg {
      display: none;
    }

    display: flex;
    flex-direction: column;
    gap: 8px;
    h4 {
      font-size: 20px;
      margin-bottom: 0;
    }

    span {
      color: #859abc;
      font-size: 14px;
    }
  }

  .navbar-date {
    @include xpay-pre-lg {
      display: none;
    }
    span {
      color: $gray-span;
    }
  }

  .navbar-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;

    @include xpay-pre-lg {
      display: flex !important;
      padding-left: 0;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .notifications-box {
      border-radius: 10px;
      border: 1px solid #d9dfff;
      background: #fafbfc;
      display: flex;
      align-items: center;
      font-weight: 500;
      gap: 8px;
      padding-inline: 6px;
      line-height: 2.5;
      cursor: pointer;
    }

    .mobile-leftside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 2;
      }

      > .mobile-back-btn {
        display: none;
        width: 30px;
        height: 30px;
        order: 2;
        cursor: pointer;

        @include xpay-pre-lg {
          display: block;
        }
      }

      > svg {
        display: none;
        @include xpay-pre-lg {
          display: block;
        }
      }

      .navbar-search {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex;
        display: none;
        background: $white;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        order: 2;

        @include xpay-pre-lg {
          display: flex;
        }

        img {
          vertical-align: middle;
          margin: auto;
          width: 30px;
          height: 30px;

          @include xpay-pre-lg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .navbar-profile {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 1;
        justify-content: flex-start;
      }
    }

    .navbar-profile > .dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      @include xpay-pre-lg {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }

      .dropdown-profile {
        display: flex;
        flex-direction: column;

        #name {
          font-weight: 500;
        }

        #phone {
          color: #859ABC;
        }

        svg {
          transform: translateY(-8px);
        }
      }

      > span {
        @include xpay-pre-lg {
          font-weight: 500;
        }
      }

      > h4 {
        display: none;
        @include xpay-pre-lg {
          display: block;
          font-size: 14px;
          color: $gray-span;
        }
      }
      p.userinfo {
        margin: 0;
      }
      .profile-dropdown-arrow {
        @include xpay-pre-lg {
          display: none;
        }
      }

      .dropdown-list {
        position: absolute;
        top: 60px;
        left: -5px;
        background: $white;
        height: max-content;
        padding: 5px;
        cursor: pointer;
        display: flex;
        transition: all 0.5s;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        list-style: none;
        width: 200px;
        border: 1px solid $light-blue-border;
        border-radius: 10px;

        li {
          display: flex;
          padding: 8px 16px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &:hover {
            background: $light-blue-secondary;
          }

          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          border-bottom: 1px solid $light-blue-border;
          }

          a {
          gap: 8px;
            display: flex;
            align-items: center;

            span {
              color: $black;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        @include xpay-pre-lg {
          left: -72px;
        }

        > svg {
          position: absolute;
          top: -7px;
          right: 5px;
        }

        span.divider {
          position: relative;
          left: 5px;
          display: inline-block;
          width: calc(100% + 10px);
          height: 1px;
          background: $table-border;
          margin: 1px 0;
        }

        .item:hover {
          background: $symbol-btn-bg;
        }

        a.item {
          color: $bold-span !important;
        }

        .item {
          padding: 5px;
          border-radius: 5px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 3px 0 0 0;

          &:last-child {
            margin-bottom: 3px;
          }

          svg {
            width: 16px;
            height: 16px;
          }

          span:last-child {
            margin-right: 5px;
            display: unset;
          }

          &.inactive {
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }

    .navbar-profile > .avatar {
      border-radius: 5px;

      > img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
