@import 'styles/references/index.scss';

.circle {
  position: relative;
  align-self: baseline;
  white-space: nowrap;
  opacity: 0.5;
  width: 50px;

  .bottomText {
    text-align: center;
    color: black;
    font-weight: 800;
    line-height: 22px;
  }

  .inner {
    text-align: center;
    margin: auto;
    justify-content: center;
    font-size: xx-large;

    svg {
      circle {
        fill: #859ABC;
      }
    }
  }

  &.reached {

    opacity: 1;

    .bottomText {
      color: black;
      font-weight: 600;
    }

    .inner {
      svg {
        circle {
          fill: $secondary;
        }
      }
    }
  }
}
