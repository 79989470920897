@import 'styles/references/index.scss';

.xpay-dashboard-referral .referral-history {
  padding: 0;
  margin: 0;
  margin-top: 32px;

  @include xpay-pre-lg {
    width: 90%;
    margin-inline: auto;
  }

  .head {
    margin: 24px;
  }

  .card-body {
    margin: unset;
    width: 100%;

    .history-table {
      margin: unset;
      margin-bottom: 24px;
    }
    
    margin: unset;

    div[role='columnheader'] {
      font-weight: 800;
      color: #859ABC;
    }

    .rdt_TableHead {
      font-weight: 800;
      color: #859ABC;
      border-top: 1px solid #D9DFFF;
      border-bottom: 1px solid #D9DFFF;
    }

    div[role='row'] {
      border: unset;
    }

    div[role='cell'] {
      font-weight: 800;
    }

    .mobile-show {
      display: flex!important;
      flex-direction: column;
      padding: unset;

      li {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 16px;

        .row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          p {
            display: flex;
            align-items: baseline;
            gap: 4px;
            font-size: 14px;
            font-weight: 500;

            tspan {
              color: $gray-span;
            }
          }
        }
      }
    }
  }
}