@import 'src/styles/references/index.scss';

.confirm-deposit-crypto-modal {
  .confirm-box {
    max-width: 340px;
    top: calc(50% - 246px) !important;
  }

  .content {
    padding: 16px;
    background: #f7f7f7;
    margin: 32px auto;

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:nth-child(2) {
          word-break: break-word;
          text-align: end;
          font-family: Roboto !important;
          text-align: left;
        }
      }
    }
  }

  .address-wallet-info {
    display: flex;
    align-items: center;

    justify-content: center;
    width: max-content;
    text-align: center;
    margin: 0px auto;
    margin-top: 16px !important;

    img {
      height: 120px;
    }
  }
}

.xpay-qr-modal {
  min-width: unset;

  .modal-content {
    text-align: center;

  }
}

.xpay-networks-modal {
  width: 480px;

  .modal-content {
    padding: 0;
    border-radius: 20px;

    > .head {
      display: none;
    }

    .xpay-panel-card {
      border-radius: 20px;

      .card-body {
        margin: unset;
        width: 100%;

        .xpay-alert .alert-box .alert-right-side .alert-icon {
          min-height: 91px;
          min-width: 48px;
        }

        ul {
          list-style: none;
          padding: unset;
          margin: unset;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            padding: 0 10px;
            cursor: pointer;

            &:hover {
              background: linear-gradient(90deg, #FFFFFF -33.94%, #F4F5FF 100%);
              border-radius: 4px;
            }

            span {
              font-size: 16px;
              font-weight: 500;
            }

            .fee {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;

              p {
                font-size: 16px;
                color: #23262F50;
              }

              span {
                font-size: 14px;
              }
            }
          }

          .ant-divider {
            margin: 12px 0;
          }
        }
      }
    }
  }
}

.xpay-assets-modal {
  width: 480px;

  .modal-content {
    padding: 0;
    border-radius: 20px;

    > .head {
      display: none;
    }

    .xpay-panel-card {
      border-radius: 20px;
      max-height: 480px;
      overflow-y: scroll;

      .card-body {
        margin: unset;
        width: 100%;

        .xpay-input {
          height: 64px;
          margin-top: 12px;

          .input-field {
            display: flex;
            border: 1px solid #EBEBEB;
            background: #F7F7F7;

            input {
              font-size: 14px;

            }

            span {
              margin-left: 8px;
            }
          }
        }

        .favorite-currencies {
          border-radius: 5px;
          background: #f7f7f7;
          padding: 16px;

          h4 {
            display: flex;
            gap: 8px;
            align-items: center;

            @include xpay-pre-lg {
              font-size: 14px !important;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }

          .currencies-box {
            display: grid;
            grid-template-columns: auto auto;
            gap: 16px;

            .currency-item {
              display: flex;
              padding: 8px;
              border-radius: 2.5px;
              background: #fff;
              height: 48px;
              gap: 4px;

              &:hover {
                cursor: pointer;
                background: #f0f0f0;
              }
            }


            .col {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              min-height: 38px;
              border-radius: 2.5px;
              p {
                color: $black;
                text-align: right;
                font-size: 14px;
                font-weight: 500;

                @include xpay-pre-lg {
                  font-size: 12px;
                }
              }

              span {
                color: #b3b3b3;
                text-align: right;
                font-size: 10px;

              }
            }
          }
        }

        h4 {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 500;
        }

        ul {
          list-style: none;
          padding: unset;
          margin: unset;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            cursor: pointer;

            &:hover {
              background: linear-gradient(90deg, #FFFFFF -33.94%, #F4F5FF 100%);
              border-radius: 4px;
            }

            .currency-title {
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                &:first-of-type {
                  font-size: 14px;
                  font-weight: 500;
                }

                &:last-of-type {
                  font-size: 12px;
                  color: #B3B3B3;
                  font-weight: 500;
                  transform: translateY(-1px)
                }
              }
            }
          }

          .ant-divider {
            margin: 12px 0;
          }
        }
      }
    }
  }
}

.xpay-crypto-deposit {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 1066px;

  @include xpay-pre-lg {
    margin: 0 auto;
    margin-top: 128px;
  }

  .deposit-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 32px;
    border-radius: 20px;
    border: 1px solid #D9DFFF;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
      padding: 16px;
    }

    .ant-divider {
      @include xpay-pre-lg {
        margin-top: 16px;
      }
    }

    .financial-info {
      display: flex;
      justify-content: space-between;
      border-radius: 10px;

      @include xpay-pre-lg {
        flex-direction: column;
        gap: 16px;
      }

      .info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        max-height: 48px;
        border-radius: 5px;
        background-color: $light-blue-secondary;
        gap: 12px;

        @include xpay-pre-lg {
          width: 100%;
          justify-content: space-between;
          padding: 0 16px;
        }

        p {
          font-weight: 500;
          font-size: 16px;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        span {
          font-weight: 500;
          font-size: 16px;
          color: #678AF5;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        &.active {
          cursor: initial;
          background-color: white;
        }
      }
    }

    .mobile-labels {
      @include xpay-lg {
        display: none;
      }

      @include xpay-pre-lg {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        label {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
      }
    }

    .deposit-warning {
      border: 1px solid #CC870066;
      background: #CC87001F;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 8px;
      margin-top: 16px;

      @include xpay-pre-lg {
        height: auto;
        padding: 8px 4px;
      }

      span {
        font-size: 16px;

        @include xpay-pre-lg {
          font-size: 12px;
        }

        &:first-child {
          color: #E59700;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 4px;
          margin-left: 6px;

          svg {
            transform: translateY(-1px);
          }
        }
      }
    }

    .xpay-input {
      .input-field {
        display: flex;
        align-items: center;
        padding-left: 10px;
        height: 64px;

        input {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 14px;
          }

          &::placeholder {

          }
        }

        .start-adornment {
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
          margin-right: 10px;

          @include xpay-pre-lg {
            display: none;
          }
        }

        .adornments {
          display: flex;
          gap: 8px;
          align-items: center;

          .xpay-button {
            @include xpay-pre-lg {
              display: none;
            }

            button {
              background: #5073FB0D;
              white-space: nowrap;

              span {
                font-weight: 500;
                color: #5073FB;
              }
            }
          }


          .currency-container {
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: #F7F7F7;
            padding: 6px;
            border-radius: 5px;
            cursor: pointer;

            span {
              font-size: 16px;
              font-weight: 700;

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .wallet-address {
      margin-top: 16px;

      @include xpay-pre-lg {
        margin-top: 32px;
      }

      .input-field {

        label {
          @include xpay-lg {
            display: none;
          }
          @include xpay-pre-lg {
            top: -16px;
          }
        }

        input {
          color: #678AF5;
          font-size: 16px;
          font-weight: 500;
          font-family: Roboto !important;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        .start-adornment {
          color: #678AF5;
          font-size: 16px;
          font-weight: 500;
        }

        .end-adornment {
          display: flex;
          gap: 16px;

          .xpay-button {
            button {
              background-color: #F6F8FF;

              &:hover {
              }

              span {
                color: #678AF5;
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .memo-input {
      margin-top: 16px;

      .input-field {
        input {
          color: #678AF5;
          font-size: 16px;
          font-weight: 500;
          font-family: Roboto;
        }

        .start-adornment {
          color: #678AF5;
          font-size: 16px;
          font-weight: 500;
        }

        .end-adornment {
          display: flex;
          gap: 16px;

          .xpay-button {
            button {
              background-color: #F6F8FF;

              &:hover {
              }

              span {
                color: #678AF5;
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .txId-input {
      margin-top: 16px;

      .input-field {
        label {
          @include xpay-lg {
            display: none;
          }
          @include xpay-pre-lg {
            top: -16px;
          }
        }
      }
    }

    .choose-network {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      background-color: $light-blue;
      border: 1px solid $light-blue-border;
      border-radius: 10px;
      height: 64px;
      cursor: pointer;
      margin-top: 24px;

      p {
        color: #3F62FE;
        font-size: 16px;
        font-weight: 500;
      }

      .chosen-network {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          font-weight: 700;
          margin-right: 8px;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }

    }

    > .xpay-button {
      max-width: unset;
      margin-top: 32px;
    }
  }

  .xpay-faq {
    margin-top: 32px;
    border: 1px solid #D9DFFF;
    border-radius: 20px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .head {
      h3 {
        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }
  }

}