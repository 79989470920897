@import "../../../../../styles/references/index.scss";

.buy-perfect-money-modal .modal-content {
  padding: 0;
  width: 480px;
  border-radius: 20px;

  > .head {
    display: none;
  }

  .xpay-panel-card {
    .card-body {
      width: 100%;
      margin: unset;

      .payment-methods {
        display: flex;
        justify-content: space-around;
        gap: 32px;
        margin: 32px auto !important;
        width: 100%;

        .payment-method {
          display: flex;
          width: 40%;
          max-width: 204px;
          height: 113px;
          position: relative;
          background-color: #FCFCFC;
          border: 1px solid #EBEBEB;
          border-radius: 10px;
          cursor: pointer;

          &.active {
            background: $primary;
            border: unset;
            cursor: initial;

            .col {
              svg {
                path {
                  stroke: white;
                }
              }

              span {
                color: white;
              }
            }
          }

          .col {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 16px;
            margin: auto;

            span {
              font-size: 16px;
            }
          }
        }
      }

      .choose-card, .account-balance {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 16px;
          font-weight: 700;
        }

        .xpay-button {
          width: unset;
          margin: unset;

          button {
            max-width: 96px;
            height: 32px;
          }
        }
      }

      .cards {
        padding: unset;
        margin: unset;
        list-style: none;
        border-radius: 10px;
        background-color: #FCFCFC;
        border: 1px solid #EBEBEB;
        margin-top: 24px;
        padding: 16px;

        .ant-divider {
          margin: 16px 0px;
        }

        li {
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          cursor: pointer;

          &:hover {
            background-color: #D9DFFF4D;
          }

          div {
            display: flex;
            gap: 8px;
            align-items: center;

            span {
              font-size: 16px;
              font-weight: 700;
            }

            img {
              width: 24px;
              height: 24px;
            }
          }

          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .accout-balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .balance-info {
        padding: unset;
        margin: unset;
        list-style: none;
        border-radius: 10px;
        background-color: #FCFCFC;
        border: 1px solid #EBEBEB;
        margin-top: 24px;
        padding: 16px;

        .ant-divider {
          margin: 16px 0px;
        }

        li {
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;

          p {
            color: #859ABC;
            font-weight: 800;
            font-size: 16px;
          }

          span {
            font-size: 16px;
            font-weight: 800;
          }

          &:hover {
            background-color: #D9DFFF4D;
          }
        }

        .error-content {
          display: flex;
          flex-direction: column;

          p {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #B23636;
            font-weight: 800;
            font-size: 14px;
            margin-top: 32px;
          }

          .charge-button {
            margin-top: 16px;
          }
        }
      }

      .xpay-button {
        margin-top: 32px;
      }
    }
  }
}