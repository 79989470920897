@import 'styles/references/index.scss';

.xpay-delete-bank-account-modal {
  background-color: white;
  padding: unset;

  h4 {
    text-align: center;
    font-size: 16px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    height: 48px;
    margin-top: 32px;

    .xpay-button {
      max-width: unset;
      width: 100%;
      
      button {
        max-width: unset;
        width: 100%;
        height: 48px;

      }
      &:last-child {
        width: 50%;
      }
    }
  }
}

.xpay-bank-accounts {
  margin-inline: auto;
  max-width: 1066px;


  @include xpay-pre-lg {
    margin-top: 120px;
  }

  .card-body {
    display: flex;

    @include xpay-pre-lg {
      flex-direction: column;
    }

    .vertical-divider {
      transform: translateY(-24px);
      height: auto;
      margin: 0 24px;
    }

    .horizontal-divider {
      margin: unset;
    }

    .bank-accounts {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include xpay-pre-lg {
        width: 100%;
      }

      .head {
        span {
          font-size: 16px;
          font-weight: 500;
        }

        .xpay-button {
          button {
            width: 80px;
            height: 32px;
            span {
              font-size: 12px;
              font-weight: 500;
            }
            svg {
            }
          }
        }
      }

      .item-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 24px;

        .item-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          svg {
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .bank-numbers {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include xpay-pre-lg {
        margin-top: 24px;
        width: 100%;
      }

      .head {
        height: 32px;

        span {
          font-size: 16px;
          font-weight: 500;
        }

        .xpay-button {
          button {
            width: 80px;
            height: 32px;
            span {
              font-size: 12px;
              font-weight: 500;
            }
            svg {
            }
          }
        }
      }

      .item-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 24px;

        .item-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
