@import "styles/references/index.scss";

.xpay-transaction-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid $table-border;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;

  .anticon.anticon-caret-down {
    color: $info;
  }
  @include xpay-pre-xl {
    height: auto;
    justify-content: unset !important;
  }

  @include xpay-pre-lg {
    width: 100%;
    max-width: unset;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  &.compact {
    height: 345px !important;

    @include xpay-pre-lg {
      height: auto !important;
    }
    .content {
      @include xpay-pre-lg {
        border-radius: 10px;
        height: auto !important;
      }

      .switch,
      .submit,
      .payment-type-options,
      .calculator {
        @include xpay-pre-lg {
          padding: 0 24px;
        }
      }
      .ant-divider {
        @include xpay-pre-lg {
          margin: 6px auto !important;
        }
      }

      .calculator > div:not(.ant-divider) {
        @include xpay-pre-lg {
          width: 100%;
        }
      }

      .calculator {
        .field {
          @include xpay-pre-lg {
            padding: 10px;
            max-height: 80px;
            border-radius: 5px;
          }

          input::placeholder {
            text-align: right;
          }

          .input-unit {
            display: none;
          }

          .ant-divider {
            display: inline-block;
          }

          .symbol {
            @include xpay-pre-lg {
              padding: 10px;
              background: $symbol-btn-bg;
              border-radius: 10px;
            }
          }
        }
      }

      .xpay-button,
      button.buy,
      button.sell {
        border-radius: 5px;
      }
    }
  }

  .currencies-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    .currency {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include xpay-pre-lg {
        flex: 33%;
      }
      svg,
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
      }

      .currency-name {
        color: $gray-span;
        font-size: 12px;
      }
    }
  }

  .content {
    display: flex;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 100%;

    @include xpay-pre-lg {
      display: flex;
      border-radius: 20px;
      padding: 24px 0;
      border: 1px solid $table-border;
      background: $white;
    }

    > .note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        color: $warning;
        font-size: 12px;
      }

      .left-side,
      .right-side {
        display: flex;
        width: max-content;
        height: max-content;
      }
      .right-side {
        justify-content: start;
        align-items: center;

        span:last-child {
          margin-right: 8px;
        }
      }
      .left-side {
        justify-content: end;
        align-items: center;
      }
    }

    .switch,
    .submit,
    .note {
      @include xpay-pre-lg {
        padding: 0 16px;
      }
    }
    .calculator > div:not(.ant-divider) {
      @include xpay-pre-lg {
        min-width: unset;
        width: calc(100% - 32px);
      }
    }

    .xpay-alert {
      @include xpay-pre-lg {
        margin-left: 0;
        margin-right: 0;
        width: calc(100% - 32px);
      }
      .message {
        @include xpay-pre-lg {
          width: 100%;
          text-align: center !important;
          font-size: 14px !important ;
        }
      }
      .alert-icon {
        display: none;
      }
    }

    .xpay-button {
      border-radius: 10px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .title {
      display: flex;
      align-items: center;
      span {
        margin-right: 6px;
        color: $info;
        font-weight: 500;

        @include xpay-pre-lg {
          font-weight: 500;
          color: $medium-black;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }

    span {
      color: $info;
    }

    .balance {
      span:last-child {
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }

  .balance-range {
    width: 100%;
    height: 50px;
    position: relative;

    span {
      position: absolute;
      top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 10px;
      color: $gray-span;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 10px;
        background: $table-border;
        margin-bottom: 5px;
      }

      &:first-child {
        right: calc(0px - 10px);
      }
      &:nth-child(2) {
        right: calc(25% - 10px);
      }
      &:nth-child(3) {
        right: calc(50% - 10px);
      }
      &:nth-child(4) {
        right: calc(75% - 10px);
      }
      &:nth-child(5) {
        right: calc(100% - 10px);
      }
    }

    input[type="range"] {
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      width: 100%; /* Specific width is required for Firefox. */
      background: transparent; /* Otherwise white in Chrome */
      position: relative;
      z-index: 10;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    input[type="range"]:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    input[type="range"]::-ms-track {
      width: 100%;
      cursor: pointer;

      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    /* Special styling for WebKit/Blink */
    input[type="range"]::-webkit-slider-thumb {
      border-radius: 50px;
      border: 0;
      background: $primary;
      cursor: pointer;
      margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

      position: relative;
      z-index: 10;
    }

    /* All the same stuff for Firefox */
    input[type="range"]::-moz-range-thumb {
      border-radius: 50px;
      border: 0;
      background: $primary;
      cursor: pointer;
      position: relative;
      z-index: 10;
    }

    /* All the same stuff for IE */
    input[type="range"]::-ms-thumb {
      border-radius: 50px;
      border: 0;
      background: $primary;
      cursor: pointer;
      position: relative;
      z-index: 10;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;

      background: $table-border;
    }

    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: $table-border;
    }

    input[type="range"]::-ms-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
    input[type="range"]::-ms-fill-lower {
      background: $table-border;
      border: 0.2px solid $zero-one-black;
    }
    input[type="range"]:focus::-ms-fill-lower {
      background: $table-border;
    }
    input[type="range"]::-ms-fill-upper {
      background: $table-border;
    }
    input[type="range"]:focus::-ms-fill-upper {
      background: $table-border;
    }
  }

  .switch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;

    button.buy,
    button.sell {
      border: 1px solid $info;
      border-radius: 10px;
      cursor: pointer;
      width: 50%;
      height: 48px !important;
      background: transparent;
      color: $info;
      font-size: 16px;
      font-weight: 500;
      opacity: 0.5;

      &.active {
        opacity: unset;
      }
      @include xpay-size(height, 40px, 12px);
      &.active {
        background: $primary;
        color: $white;
        border: 0;
      }
    }
  }

  .calculator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;

    > .ant-divider {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .field {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $table-border;
      border-radius: 10px;
      padding: 4px;
      min-width: 100%;
      max-height: 48px;

      @include xpay-pre-lg {
        min-width: none;
        width: 100%;
      }

      .options {
        min-width: 28ch;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include xpay-pre-lg {
          display: none;
        }
        .select-available-balance {
          border-radius: 10px;
          color: $info;
          margin-left: 6px;
          padding: 6px;
          background: rgba(80, 115, 251, 0.05);
          cursor: pointer;
        }
      }

      .symbol {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 5px;
        min-width: 150px;
        min-height: 28px;
        padding: 6px;
        background: $symbol-btn-bg;
        border-radius: 10px;
        width: max-content;

        &.symbol-irt {
          background: transparent;
        }

        .coin {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 5px;

          img {
            width: 28px;
            height: 28px;
          }

          span {
            width: max-content;
            font-size: 12px;
            font-weight: 500;
          }
          span:last-child {
            font-family: "Montserrat";
            color: $light-info;
          }
        }
      }

      .ant-divider {
        height: 28px;
        margin: 0 12px;
        display: none;

        &.active {
          display: inline-block;
        }
      }
      input {
        padding: 0;
        border: none;
        outline: none;
        width: 100%;
        min-height: 28px;

        &::placeholder {
          text-align: left;
        }

        &.max-content {
          width: max-content !important;
          @include xpay-pre-lg {
            width: 100% !important;
          }
        }

        @include xpay-size(font-size, 16px, 12px);
      }
      input::placeholder {
        color: $transaction-box-placeholder;
      }
      > span.input-unit {
        color: $transaction-box-placeholder;
        padding: 0 8px;
      }
    }

    .input-info-labels {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .input-info-label {
      font-size: 11px;
      > span:last-child {
        color: $info !important;
        font-weight: 500;
        margin-right: 4px;
      }
      &:first-child {
        text-align: left;
        margin-left: auto;
        font-weight: 500;
      }
      &:last-child {
        margin-right: auto;
        color: $info !important;
        font-weight: unset;
      }
    }
  }
  .payment-type-options {
    margin-left: auto;
    margin-bottom: 32px;
    margin-top: 24px;

    &.not-compact {
      @include xpay-pre-lg {
        padding: 0 16px 16px;
        display: flex;
        margin: 0;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      .payment-type-title {
        padding-bottom: 8px;
        font-weight: 500;
        color: $info;
      }

      .ant-radio-group {
        @include xpay-pre-lg {
          width: 100%;
        }
        .ant-radio-wrapper {
          @include xpay-pre-lg {
            align-items: center;
            font-size: 12px;
          }
          > span:last-child {
            span {
              font-weight: 400 !important;
            }
            span:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }

    > label {
      width: max-content;
    }
    .ant-radio-group {
      margin-right: 32px;

      @include xpay-pre-lg {
        width: 100%;
        margin-right: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      > label:not(.payment-type-title) {
        > span:last-child {
          > span:first-child {
            font-weight: 500;
          }
        }
      }
    }
  }
  .payment-type-options-pre-lg {
    display: none;
    @include xpay-pre-lg {
      display: unset;
    }
    .ant-radio {
      display: none;
    }
    .ant-radio-group {
      width: 100%;
    }
    .ant-radio-wrapper {
      padding: 12px;
      background: $ant-form-bg;
      width: 100%;
      margin: 8px auto;
      border-radius: 8px;
      font-size: 12px;

      &.ant-radio-wrapper-checked {
        background: $secondary;
        color: $white;
      }
    }
  }

  .submit {
    width: 100%;

    .xpay-button {
      position: relative;
      max-width: unset;
      margin: 0;
      height: 48px;
      button {
        background: $primary;
      }
      button {
        @include xpay-pre-xl {
          height: 48px;
        }
      }
    }

    .xpay-button::before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("./../../../public/assets/images/slides/mask.svg");
      background-position: -140px;
      background-repeat: no-repeat;
      background-attachment: local;
      background-size: 50%;
    }
  }
}
