@import 'src/styles/references/index.scss';

.search-box-modal {
  .xpay-search-box {
    width: 100%;
  }
}

.xpay-search-box {
  display: flex;
  width: 38%;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  padding: 24px;
  border: 1px solid #d9dfff;
  background: $white;
  gap: 24px;

  @include xpay-pre-lg {
    width: 100%;
    border-radius: 10px;
    padding: 16px;
    gap: 16px;
  }

  .search-bar {
    display: flex;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    background: #f7f7f7;
    height: 64px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    input {
      width: 90%;
      font-size: 16px;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;
      appearance: none;
      -webkit-appearance: none;

      @include xpay-pre-lg {
        font-size: 14px;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  .favorite-currencies {
    border-radius: 5px;
    background: #f7f7f7;
    padding: 16px;

    h4 {
      display: flex;
      gap: 8px;
      align-items: center;

      @include xpay-pre-lg {
        font-size: 14px !important;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .currencies-box {
      display: grid;
      grid-template-columns: auto auto;
      gap: 16px;

      .currency-item {
        display: flex;
        padding: 8px;
        border-radius: 2.5px;
        background: #fff;
        height: 48px;
        gap: 4px;

        &:hover {
          cursor: pointer;
          background: #f0f0f0;
        }
      }


        .col {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          min-height: 38px;
          border-radius: 2.5px;
          p {
            color: $black;
            text-align: right;
            font-size: 14px;
            font-weight: 500;

            @include xpay-pre-lg {
              font-size: 12px;
            }
          }

          span {
            color: #b3b3b3;
            text-align: right;
            font-size: 10px;

          }
        }
      }
    }
  }

  h4 #list {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 16px;

    @include xpay-pre-lg {
      font-size: 14px;
    }
  }

  .currencies-list {
    overflow-y: scroll;
    max-height: 260px;

    @include xpay-pre-lg {
      max-height: 440px;
      overflow: auto;
    }

    .ant-divider {
      margin: unset;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;

      &:hover {
        cursor: pointer;
        background: #f0f0f030;
      }

      .name-icon {
        display: flex;
        gap: 16px;

        p {
          display: flex;
          color: #000;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          align-items: center;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
      }

      .symbol {
        color: #b3b3b3;
        font-size: 12px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 10px;
        }
      }
    }
  }

