@import 'src/styles/references/index';

.xpay-authentication-card {
  border-radius: 10px;
  border: 1px solid var(--Primary-5, #d9dfff);
  background: var(--White, #fff);
  padding: 16px;
  width: 100%;
  max-width: 323px;
  height: auto;
  // margin: auto;

  h3 {
    display: flex;
    width: fit-content;
    margin-top: 16px;
    margin-inline: auto;
    color: #5376fa;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    gap: 8px;
    @include xpay-pre-lg {
      font-size: 14px;
    }

    svg {
      transform: unset;
      align-items: center;
      justify-content: center;
    }
  }

  h4 {
    &:nth-child(2) {
      margin-top: 40px;
    }
  }

  .level0-background {
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 80px;
    font-size: 48px;
    font-weight: 500;
    background: linear-gradient(96deg, #e5e5e5 0%, #fafafa 100%);
    color: #4b6efc;
  }

  .level1-background {
    color: $white;
    background: linear-gradient(96deg, #f1f1f1 0%, #00c0c0 100%);
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 80px;
    font-size: 48px;
    font-weight: 500;
  }

  .level2-background {
    color: $white;
    background: linear-gradient(96deg, #f1f1f1 0%, #0053c0 100%);
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 80px;
    font-size: 48px;
    font-weight: 500;
  }

  svg {
    margin: auto;
    display: flex;
    transform: translateY(-9px);
  }

  .possibilities svg,
  .requirements svg {
    margin: unset;
    display: unset;
    transform: unset;
    align-self: center;
  }

  .possibilities,
  .requirements {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #859abc;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .possibilities .info-row,
  .requirements .info-row {
    display: flex;
    gap: 8px;
  }

  .xpay-button {
    width: 100%;
    max-width: unset;
    margin-top: 72px;
    button span {
      color: $white;
      @include xpay-pre-lg {
        font-size: 14px;
      }
    }
  }

  .upgrade-to-2-button {
    margin-top: 136px;
  }

  .view-button {
    width: 100%;
    max-width: unset;
    button.primary {
      background-color: #112a5d;
      svg {
        transform: unset;
        margin: unset;
      }
      span {
        font-size: 14px;

        color: $white;
      }
    }
  }
}
