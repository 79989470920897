@import 'src/styles/references/index.scss';

.wallet-box {
  display: flex;
  width: 100%;
  border: 1px solid #D9DFFF;
  grid-area: wallet;
  position: relative;

  @include xpay-pre-lg {
    width: 100%;
  }

  .card-body {
    .top-section {
      display: flex;
      justify-content: space-between;
      .col {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h5 {
          font-size: 18px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 16px;
          }
        }

        span {
          font-size: 24px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 20px;
          }
        }
      }
    }

    .bottom-section {
      display: grid;
      grid-auto-flow: column;
      margin-top: 36px;
      gap: 16px;

      .col {
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        height: 100%;

        h5 {
          display: flex;
          gap: 8px;
          font-size: 14px;
          font-weight: 500;
          align-items: center;
          white-space: nowrap;

          @include xpay-pre-lg {
            font-size: 12px;
            gap: 2px;
          }
        }

        span {
          font-size: 14px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
      }
    }
  }
}
