@import 'src/styles/references/index.scss';

.authentication-box {
  width: 100%;
  height: 100%;
  background: $white;
  padding: 24px;
  border-radius: 20px;
  overflow-y: scroll !important;
  scrollbar-width: none;
  border: 1px solid #d9dfff;
  grid-area: authentication;

  @include xpay-pre-lg {
    width: 100%;
  }

  .head {
    display: flex;
    justify-content: space-between;

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }

    .authentication-button {
      button {
        width: 90px;
        height: 32px;
        span {
          font-size: 12px;
        }
      }
    }
  }

  .card-body {
    .authentication-info {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        gap: 4px;
        flex-direction: column;
        @include xpay-pre-lg {
          flex-direction: column;
        }
        h5 {
          font-size: 14px;
          font-weight: 500;
        }
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .authentication-steps {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      gap: 8px;

      .step {
        width: 40%;
        // max-width: 99px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #D9DFFF;

        &.active {
          background: $primary;
        }

        @include xpay-pre-lg {
          max-width: 80px;
        }
      }
    }
  }
}
