@import 'styles/references/index.scss';

.xpay-dashboard-authentication {
  margin: 0px auto 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 25px;
  height: auto;
  gap: 32px;
  margin-bottom: 100px;
  grid-area: authentication;
  max-width: 1066px;

  @include xpay-pre-lg {
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: unset;
    padding: 0 24px;
  }

  .xpay-alert {
    border: none !important;
    padding: 0px !important;
  }
  .mobile-show {
    display: none;
    @include xpay-pre-lg {
      display: unset;
    }
  }

  .xpay-panel-card {
    .card-body {
      .row {
        display: flex;

        .box {
          display: flex;
          gap: 8px;
          margin-left: 25%;
          @include xpay-pre-lg {
            margin-left: unset;
            margin: auto;
            flex-direction: column;
            img {
              margin: auto;
            }
          }

          .diamond {
            border-radius: 5px;
            padding: 16px;
            opacity: 0.5;
            background: linear-gradient(96deg, #e5e5e5 0%, #fafafa 100%);
          }
        }

        // @include xpay-pre-lg {
        //   flex-direction: column;
        //   margin: auto;
        //   justify-content: center;
        //   align-items: center;
        // }
      }

      .mobile-content {
        display: flex;
        width: 100%;
        gap: 16px;
        box-sizing: border-box;
        justify-content: space-around;

        .diamond {
          border-radius: 5px;
          padding: 34px 16px;
          opacity: 0.5;
          background: linear-gradient(96deg, #e5e5e5 0%, #fafafa 100%);
        }
        .col {
          
          .row {
            justify-content: space-between;
            gap: 64px;
            font-weight: 500;

            span {
              color: #859ABC;
            }
          }
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        @include xpay-pre-lg {
          &:first-child {
            margin-bottom: 8px;
          }
        }

        .row {
          gap: 4px;
          font-size: 14px;
          justify-content: unset;
          font-weight: 500;
          @include xpay-pre-lg {
            font-size: 12px;
          }

          span {
            @include xpay-pre-lg {
              font-size: 12px;
            }
            color: #859ABC;
          }
        }
      }
    }
  }

  .authentication {
    .card-body {
      .row {
        gap: 2%;
        justify-content: center;
        @include xpay-pre-lg {
          flex-direction: column;
          gap: 16px;
          align-items: center;
        }
      }
    }
  }

  .xpay-alert {
    opacity: 0.9;
    border: 1px solid $info;
    background: unset !important;
    margin: unset !important;
    .alert-box .alert-content .message {
      font-size: 12px !important;
    }
  }

  .xpay-helper-box {
    margin-top: 24px;
  }
}
