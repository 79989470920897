@import 'styles/references/index.scss';

.xpay-add-card {
  max-width: 1066px;
  margin-inline: auto;
  @include xpay-pre-lg {
    margin-top: 120px;
    width: 90%;
  }

  .head {
    align-items: center;

    h3 {
      @include xpay-pre-lg {
        font-size: 14px;
      }
    }
    .xpay-button {
      button {
        height: 32px;
        border-radius: 5px;
        background: #fafbfc;
        padding: 8px 16px;
        span {
          color: #1552f0;
          font-size: 12px;
          font-weight: 500;
        }
        svg {
          fill: #1552f0;
        }
      }
    }
  }
  .card-body {
    display: flex;

    @include xpay-pre-lg {
      flex-direction: column;
    }

   .vertical-divider {
      transform: translateY(-24px);
      height: auto;
      margin: 0 24px;
      @include xpay-pre-lg {
        display: none;
      }
    }

    .add-section {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include xpay-pre-lg {
        width: 100%;
      }

      .field-label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .field {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        border-radius: 10px;
        border: 1px solid #ebebeb;
        background: #fcfcfc;
        padding: 16px;
        width: 100%;
        align-items: center;

        input {
          width: 50%;
          font-size: 16px;
          padding: 0;
          margin: 0;
          border: none;
          outline: none;
          background-color: transparent;
          box-shadow: none;
          appearance: none;
          -webkit-appearance: none;
          direction: ltr;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }

      .xpay-button {
        margin-top: 32px;
        max-width: unset;
        border-radius: 5px;
        
        button {
            border-radius: 5px;
        }
      }
    }

    .hint-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 50%;

      @include xpay-pre-lg {
        margin-top: 24px;
        width: 100%;
      }

      .xpay-alert {
        width: fit-content;
        margin: auto;
        padding: 8px;

        .alert-box {
          .alert-ride-side {
            .alert-icon {
              @include xpay-pre-lg {
                min-height: 48px !important;
              }
            }
          }
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }
    }
  }
}
