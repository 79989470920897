    @import 'styles/references/index.scss';

    .xpay-authentication-stepper {
    display: flex;

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d9dfff;
        color: #859abc;
        text-align: right;
        font-size: 16px;
        font-weight: 500;
          margin-inline: auto;
        }

        .text {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #859abc;
        text-align: right;
        }
    }

    .line {
        flex: 1;
        height: 2px;
        background-color: #ccc;
        margin-top: 3%;

        &.active {
            background-color: #112a5d;
        }
    }

    .step + .line {
        margin-top: 3%;
    }

    .step.active {
        .circle {
        background-color: #112a5d;
        color: #fff;
        }

        .text {
        color: #112a5d;
        }
    }
    }
