@import 'src/styles/references/index';

.xpay-orders-navigation {
  height: 100vh !important;

  .ant-drawer-header {
    .ant-drawer-header-title {
      display: flex;
      justify-content: space-between;

      .ant-drawer-title {
        width: fit-content;
        display: flex;
        flex: unset;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
}