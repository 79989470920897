@import "styles/references/index.scss";

.xpay-two-step-verify {
    .card-body {
        .main {
            width: 100%;
            margin-top: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &.download {
                p {
                    width: 100%;
                    font-size: 12px;
                    width: 100%;
                    color: $bold-span;
                }

                div {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    margin: 32px auto;

                    @include xpay-pre-lg {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}
