@import 'styles/references/index.scss';

.xpay-authentication-one {
  max-width: 480px;
  margin: auto;
  @include xpay-pre-lg {
    margin-top: 105px;
    width: 90%;
  }

  .step-warning-content {
    .bg {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      span {
        position: absolute;
        font-weight: 500;
        font-size: 32px;
        color: white;
        margin-top: 10%;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;

      &:last-child {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    span {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      gap: 8px;
      margin-top: 16px;
    }

    .xpay-button {
      margin-top: 24px;
      max-width: unset;
      button {
        max-width: unset;

        span {
          margin: unset;
        }
      }
    }
  }

  .step-agreement-content {
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
    }

    .buttons {
      margin-top: 24px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 16px;

      .xpay-button {
        max-width: unset;
      }
    }
  }

  .step-1-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .field {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      border-radius: 10px;
      border: 1px solid #ebebeb;
      background: #fcfcfc;
      width: 100%;
      align-items: center;

      input {
        width: 50%;
        font-size: 16px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        appearance: none;
        -webkit-appearance: none;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .value-input {
        width: 100%;
        padding: 16px;
      }

      .date-picker-custom {
        width: 100%;
        height: calc(100% - 16px);
      }
    }

    .own-number {
      display: flex;
      gap: 8px;
      svg {
        cursor: pointer;
      }
      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .xpay-button {
      max-width: unset;
      margin-top: 24px;
      button {
        border-radius: 10px;
        background: linear-gradient(95deg, #3f62fe -25.7%, #678af5 112.1%);
      }
    }
  }

  .step-2-content {
    .hint {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px !important;
    }

    .ant-divider {
      margin: 16px 0;
    }

    .row {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 16px;
        font-weight: 500;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        opacity: 0.5;
      }
    }

    .confirm {
      max-width: unset;
      margin-top: 64px;

      button {
        border-radius: 10px;
        background: linear-gradient(95deg, #3f62fe -25.7%, #678af5 112.1%);
      }
    }

    .reject {
      max-width: unset;
      margin-top: 24px;

      button {
        border-radius: 10px;
        background: #112a5d;
      }
    }
  }
}
