@import "styles/references/index.scss";

.xpay-two-step-verify {
    .card-body {
        .main {
            &.recovery {
                img {
                    width: 100px;
                    height: 100px;
                }
                p {
                    font-family: "Montserrat" !important;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}
