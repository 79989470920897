@use 'sass:selector';

$bg: #F7FBFF;
$new-bg: #F7FBFF;
$primary: #678AF5;
$secondary: #112A5D;

$black: #000000;
$zero-one-black: #010101;
$blue: #1890ff;
$transparent-blue: #6581fe;

$dark-primary: linear-gradient(83.63deg, #4e5596 -36.87%, #6269aa 117.86%);
$dark-gray: #383852;

$medium-gray: #a8a8a8;
$medium-white: #e9e9e9;
$read-notification: #e9e9ea;
$extra-light-gray: #fbfbfb;

$deposit-shadow: linear-gradient(0deg, #ffffff, #ffffff), #f9fcff;

$disabled-gray-tag: #f6f6f6;
$disabled-tag-text: #9e9e9e;

$light-green: #65c466;
$orange: #e93c3c;
$light-red: #f9f1f0;
$cyan: #2ed5ff;
$white-cyan: #c8f1ff;
$sky-blue: #379aff;
$light-blue: #EBF5FF;
$light-blue-secondary: #F5FAFF;
$light-blue-border: #E0F0FF;

$ant-form-bg: #f7f7f7;
$light-success: #41daa3;
$skeleton-bg: #f4f4f4;

$input: #f9f9fd;
$star: #22bdff;
$access: #678af5;

$popover-danger: #ffbdbd;
$popover-warn: #fffbce;
$popover-success: #41daa3;

$success: #2ac769;
$danger: #e93c3c;
$light-info: #5073fb;
$info: #4f46e0;
$primary: #3f62fe;
$warning: #f6a609;
$medium-black: #272727;

$badge-success-bg: rgba(54, 178, 137, 0.04);
$badge-success-text: #36B289;

$badge-waiting-bg: rgba(204, 156, 61, 0.04);
$badge-waiting-text: #CC9C3D;

$badge-error-bg: rgba(178, 54, 54, 0.04);
$badge-error-text: #B23636;

$danger-badge: #FEE6E6;
$light-cyan-badge: #3f62fe1a;

$warning-alert-border: #CC8700;
$warning-alert: #FEF6E6;
$primary-alert: #eef2fe;
$gray-text: #a8a9aa;
$gray-span: #859abc;
$bold-span: #383952;
$table-head-text: #9697a0;
$table-head-bg: #d9dfff33;
$table-head-bg-2: #f1f8ff;

$white: #ffffff;
$c-white: #cccccc;
$nine-white: #999999;
$table-border: #d9dfff;
$d9Gray: #d9d9d9;

$symbol-btn-bg: #f1f8ff;

$alert-message: #383952;

$chart-bg: #efefef;

$night-switch-bg: #5d7294;

$transaction-box-placeholder: #a8a9aa;

$asset-bg: #eff2ff;

$level-badge-bg: #3f62fe99;

$scroll-thumb-bg: #c9cfd8;

$level-up-bg: #4b6efc;

$transaction-card-bg: #fefeff;

$chart-shadow: #ff4e82;

$skeleton-bg-a: #5c6168;
$skeleton-bg-b: #d2dae6;
$skeleton-bg-c: #82868b;

//outdated
$success-badge: #d4f4e1;
$success-badge-text: #005f27;
$waiting-badge: rgba(246, 166, 9, 0.2);

$primary-gradient: linear-gradient(95deg, #3F62FE -25.7%, #678AF5 112.1%);
$login-gradient: linear-gradient(180deg, #FFFFFF 0%, #EBF5FF 100%);

$gold: #B88014;
