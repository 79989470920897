@import 'styles/references/index.scss';

.orders-trade {
  width: 100%;

  .desktop-show {
    @include xpay-pre-lg {
      display: none;
    }

    .xpay-data-table {
      width: 100%;
      padding: 0;
      color: $bold-span;
      margin: 0 auto;

      .rdt_Table {
        border-top: 1px solid $table-border;

        .rdt_TableHeadRow {
          color: $table-head-text;
        }
      }

      [role='columnheader'] {
        justify-content: center;
        width: 100%;
        > div {
          overflow: unset !important;
        }
      }

      [role='cell'] {
        justify-content: center;
        font-weight: 500;

        .column-date {
          display: flex;
          flex-direction: column;
        }
      }

      [data-column-id='createdOn'] {
        .column-date {
          span {
            display: inline-block;
            width: 100%;
          }

          span:last-child {
            font-size: 10px;
            color: $medium-gray;
          }
        }
      }

      [data-column-id='currencyName'] {
        img {
          width: 26px;
          height: 26px;
          margin-left: 16px;
        }
      }

      [data-column-id='date'] {
        .column-date {
          span {
            display: inline-block;
            width: 100%;
          }

          span:last-child {
            font-size: 10px;
            color: $medium-gray;
          }
        }
      }

      [data-column-id='state'] {
        .xpay-badge {
          min-width: 130px;
          display: flex;
          justify-content: center;

          > div {
            height: 30px;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
          }
        }
      }

      [data-column-id='detail'] {
        span {
          cursor: pointer;

          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }

  .mobile-show {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include xpay-pre-lg {
      display: flex;
      padding: 0;
    }

    .xpay-button {
      max-width: unset !important;

      button {
        @include xpay-pre-lg {
          width: 100%;
        }
      }
    }

    > li {
      background: $white;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $table-border;
      border-left: 0;
      border-right: 0;
      max-width: 425px;

      &:first-of-type {
        margin-top: 16px;
      }

      &:not(li:last-child) {
        border-bottom: 0px;
      }

      padding-bottom: 32px;

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right,
        .left {
          display: flex;
          width: max-content;
        }
      }

      > div:first-child {
        .right {
          justify-content: flex-start;
          align-items: center;

          img,
          p,
          span {
            margin-left: 8px;
          }

          img {
            width: 25px;
            height: 25px;
          }

          p {
            font-size: 12px;
            font-weight: 500;
          }

          span {
            color: $gray-text;
            font-family: 'Montserrat';
            font-size: 12px;
          }
        }

        .left {
          justify-content: flex-start;
          align-items: center;

          p {
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(2) {
        .left,
        .right {
          p:last-child {
            margin-right: 3px;
          }

          span {
            font-weight: 500;
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(3) {
        .right {
          span {
            margin-right: 3px;
            font-size: 12px;
            color: $gray-text;
          }
        }

        .left {
          .xpay-badge {
            .waiting {
              color: $warning;
            }
          }
        }
      }

      .xpay-button {
        max-width: unset;
        margin: 0;

        button {
          border-color: $table-border !important;
          height: 38px;
        }
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    @include xpay-pre-lg {
      flex-direction: column;
      background: $white;
      padding: 16px;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      display: flex;
      gap: 16px;
      .search {
        button {
          width: 104px;
          height: 48px;
          border-radius: 5px;
          background: #36b289;
          border: none;

          span {
            color: white;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .clear-search {
        button {
          width: 104px;
          height: 48px;
          border-radius: 8px;
          border: 1px solid #b23636;

          span {
            color: #b23636;
            font-size: 14px;
            font-weight: 500;
          }

          &:hover {
            background: #b23636;
            span {
              color: white;
            }
          }
        }
      }
    }

    .fields {
      width: fit-content;
      @include xpay-pre-lg {
        flex-direction: column;
      }

      input {
        height: 38px !important;
      }

      > div {
        // min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;

        @include xpay-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.xpay-dropdown) {
          margin-right: 16px;

          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }
      }

      &:first-child {
        @include xpay-pre-lg {
          margin-top: 16px;
        }
      }

      .ant-dropdown-trigger {
        width: fit-content;
        button {
          border: 1px solid $table-border !important;
          height: 48px;

          svg {
            fill: $primary;
          }

          &:hover {
            background-color: $primary;

            svg {
              fill: white;
            }
          }

          > span {
            color: black;
            font-weight: 500;
          }
        }

        &.ant-dropdown-open {
          &:hover {
            button span {
              color: white;
            }
          }
        }

        &:hover {
          button span {
            color: white;
          }
        }
      }

      .xpay-calendar {
        cursor: pointer;
        width: 130px;
        height: 48px;

        .xpay-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background: $white;
            height: 48px;

            input {
              cursor: pointer;
              font-weight: 500;
              &::placeholder {
                color: black;
              }
              @include xpay-pre-lg {
                font-size: 12px;
              }
            }

            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
}
