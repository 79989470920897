@import 'styles/references/index.scss';

.xpay-add-iban {
  max-width: 1066px;
  margin-inline: auto;
  @include xpay-pre-lg {
    margin-top: 120px;
  }

  .head {
    align-items: center;
    .xpay-button {
      button {
        height: 32px;
        border-radius: 5px;
        background: #fafbfc;
        padding: 8px 16px;
        span {
          color: #1552f0;
          font-size: 12px;
          font-weight: 500;
        }
        svg {
          fill: #1552f0;
        }
      }
    }
  }
  .card-body {
    display: flex;

    @include xpay-pre-lg {
      flex-direction: column;
    }

    .vertical-divider {
      transform: translateY(-24px);
      height: auto;
      margin: 0 24px;
      @include xpay-pre-lg {
        display: none;
      }
    }

    .add-section {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include xpay-pre-lg {
        width: 100%;
      }

      .field-label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .field {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        border-radius: 10px;
        border: 1px solid #ebebeb;
        background: #fcfcfc;
        padding: 16px;
        width: 100%;
        align-items: center;

        div {
            display: flex;
            width: 90%;
            justify-content: left;
            align-items: center;
            gap: 8px;
          input {
            width: 100%;
            font-size: 16px;
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            background-color: transparent;
            box-shadow: none;
            appearance: none;
            -webkit-appearance: none;
            direction: ltr;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }

          span {
            align-self: flex-start;
          }
        }
      }

      .xpay-button {
        margin-top: 32px;
        max-width: unset;
        border-radius: 5px;

        button {
          border-radius: 5px;
        }
      }
    }

    .hint-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 50%;

      @include xpay-pre-lg {
        margin-top: 24px;
        width: 100%;
      }
      .xpay-alert {
        width: fit-content;
        margin: auto;
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
