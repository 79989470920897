@import 'styles/references/index.scss';

.xpay-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1020;
  padding: 32px 16px;
  min-width: 412px;

  @include xpay-pre-lg {
    min-width: 324px;
  }

  .xpay-button {
    max-width: unset !important;
  }

  .modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;

    @include xpay-pre-lg {
      max-width: 100% !important;
    }

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $white;
      margin-bottom: 32px;

      .close {
        display: flex;
        align-items: center;
      }

      h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .xpay-input {
      width: 100%;
    }

    .description {
      p {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        color: $gray-span;
      }
    }

    .confirm-box {
      .buttons {
        width: 100%;
        display: flex;
        gap: 16px;
        height: 48px;

        .xpay-button {
          button {
            max-width: unset;
            width: 100%;
            height: 100%;
          }

          &:first-child {
            width: 75%;
          }

          &:last-child {
            width: 25%;
          }
        }
      }
    }
  }
}
