@import 'src/styles/references/index.scss';

.xpay-comment-modal {
    width: 480px;
    @include xpay-pre-lg {
      width: 90%;
    }
  .modal-content {
    padding: unset;
    border-radius: 20px;


    > .head {
      display: none;
    }

    .xpay-comment-card {
      .card-body {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-bottom: unset;

        .comment-union {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .comment-warning {
          margin-inline: auto;
          width: 178px;
          height: 186px;
        }

        .row {
          display: flex;
          gap: 8px;
          margin-top: 32px;

          p {
            font-size: 16px;
            font-weight: 500;
            color: $gold;
            line-height: 1.5;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }

          &.has-checkbox {
            p {
              color: $black;
            }
          }
        }

        .xpay-button {
          margin-top: 48px;
        }
      }
    }
  }
}