@import 'src/styles/references/index';

.xpay-data-table {
  margin: 24px 0;

  .no-data {
    padding: 16px;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-thumb-bg;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }

  div[role='table'] div[role='rowgroup'] div[role='row'] {
    height: 62px !important;
  }

  header {
    margin-bottom: 16px;
  }
  .pagination-footer {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    > .anticon {
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $table-border;
      height: 40px;
      width: 40px;
      color: $info;
      transition: 0.4s;

      &:hover {
        background: $primary;
        color: $white;
        transition: 0.4s;
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;

      .xpay-button {
        margin-right: 10px;
        button {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid $table-border !important;

          &.info {
            background: $primary;
          }
        }
      }
    }
  }

  header {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    .xpay-input {
      @include xpay-size(width, 95%, 80%);
      max-width: 344px;

      .input-icon {
        left: 4% !important;
        right: unset !important;
      }
      input {
        width: 70%;
      }
    }
    .xpay-dropdown {
      border-radius: 10px;
      @include xpay-pre-md {
        width: 17%;
        min-width: unset;
        max-width: unset;
      }
      .dropdown-selector {
        a {
          svg {
            @include xpay-pre-md {
              margin-left: unset;
            }
          }
          span {
            @include xpay-pre-md {
              display: none;
            }
          }
        }
        .anticon {
          @include xpay-pre-md {
            display: none;
          }
        }
      }
    }
  }
  &.non-header {
    header {
      display: none !important;
    }
  }
}
