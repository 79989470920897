@import 'src/styles/references/index';

.xpay-alert {
  position: relative;
  min-height: 56px;
  padding: 14px 16px;
  border-radius: 10px;
  border: 1px solid;
  margin: 24px auto;
  width: 100%;
  height: max-content;

  &:first-child {
    margin-top: 0;
  }

  &.mobile-show {
    display: none !important;

    @include xpay-pre-lg {
      display: flex !important;
    }
  }

  > .head-icon {
    border-radius: 30px;
    background: $warning;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    @include xpay-pre-lg {
      margin: 8px auto !important;
    }

    svg {
      width: 14px;
      height: 12px;
    }
  }

  .alert-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .alert-right-side {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;

      .title {
        font-weight: 500;
      }

      .alert-icon {
        display: flex;
        margin-left: 8px;
        min-width: 48px;
        min-height: 80px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
      }

      .alert-icon svg {
        height: 36px;
        margin-inline: auto;

        @include xpay-pre-lg {
          height: 20px;
        }
      }
    }

    .alert-left-side {
      display: flex;
      align-self: start;

      > .ant-icon,
      > svg {
        margin-bottom: auto;
        margin-top: 0;
      }
    }
  }

  &.danger {
    background: $danger-badge;
    border: 1px solid #CC0000;

    .alert-box {
      .alert-right-side {
        .alert-icon {
          background: $badge-error-text;
          svg {
            fill: none;
          }
        }
      }
    }

    .title,
    .anticon-close {
      color: $danger;
    }
  }

  &.success {
    background: $success-badge;
    border-color: $success;

    .title,
    .anticon-close {
      color: $success;
    }

    .alert-box {
      .alert-right-side {
        .alert-icon {
          background: $badge-success-text;
          svg {
            fill: none;
          }
        }
      }
    }
  }

  &.closed {
    display: unset;
  }

  .closable-icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  &.warning {
    background: $warning-alert;
    border-color: $warning-alert-border;

    svg {
      width: 24px;
      height: 24px;
    }

    .closable-icon {
      width: 8px;
      height: 8px;
    }

    svg path {
      fill: white;
    }


    .closable-icon svg path {
      fill: black;
    }

    .alert-icon {
      background: #F5A200;
    }

    .title,
    .anticon-close {
      color: #F5A200;
    }
  }

  &.info {
    border-color: $info;

    background: $primary-alert;

    .title,
    .anticon-close {
      color: $info;
    }
  }

  .alert-box {
    display: flex;

    svg {
      fill: black;
    }

    .alert-content {
      max-width: 90%;
      margin-bottom: 6px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .title {
        font-size: 16px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .message {
        margin-top: 8px;
        color: #383952;
        font-weight: 400;
        text-align: right;
        font-size: 14px;
        @include xpay-pre-lg {
          font-size: 12px;
        }

        p {
          font-size: 16px;
          line-height: 29px;

          text-align: right;

          @include xpay-pre-lg {
            font-size: 14px;
            line-height: 25px;

          }
        }
      }
    }
  }
}
