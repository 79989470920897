@import 'src/styles/references/index.scss';

.sidebar-main {
  &.mobile-show {
  }

  > .sidebar-item {
    position: relative;
    margin-right: 0px;
    padding: 0px 24px;
    min-height: 48px;
    max-width: 240px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    justify-content: flex-start;

    &.logout {
      margin-top: auto;
      margin-bottom: 12px;
    }

    &.open {
      transition: width 0.3s;
      width: 227px;
    }

    &.closed {
      width: 87px;
      transition: width 0.3s;
      justify-content: center;

      &.parent {
        .left-arrow-icon {
          display: none;
        }
      }
    }

    @include xpay-pre-lg {
      max-width: unset;
      width: 100%;
      justify-content: space-between;
    }

    &.back-button {
      justify-content: space-between;
      margin-bottom: 24px;
      border: 1px solid $gray-span;

      .content {
        order: 2;
      }

      .content .title {
      }

      > svg {
        transform: rotate(180deg);

        path {
          order: 1;
        }
      }
    }

    .content svg {
      width: 21px !important;
    }

    .content .home-item {
      fill: none;

      path {
        fill: $gray-span;
        stroke: $gray-span;
      }
    }

    .content .anticon svg {
      fill: $gray-span;
    }

    &.active {
      justify-content: space-between;
      background: $primary-gradient;

      &.closed {
        justify-content: center;
      }

      .content svg .content svg {
      }

      .content {
        svg {
          fill: none;

          path {
            fill: $primary;
            stroke: $white;
          }

          rect {
            stroke: $white;
          }
        }

        .home-item {
          fill: none;

          path {
            fill: $white;
          }

          rect {
            stroke: $white;
          }
        }
      }

      .title {
        color: $white;
      }

      &.filled {
        .content {
          svg {
            rect {
              // fill: $white;
            }

            path {
              fill: $gray-span;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: nowrap;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    .title {
      margin-right: 13px;
      color: $gray-span;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0em;
      width: max-content;
      padding-top: 4px;
    }

    > .left-arrow-icon {
      margin-right: auto;

      @include xpay-pre-lg {
        display: block;
      }

      > path {
        @include xpay-pre-lg {
          fill: $gray-span;
        }
      }

      &.active {
        path {
          stroke: $white;
        }
      }
    }
  }
}
