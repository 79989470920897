@import 'src/styles/references/index.scss';

.search-box-modal {

  @include xpay-pre-lg {
    width: 100% !important;
    height: 100%;
    padding: 0;

    .modal-content {
      height: 100%;
      width: 100%;
    }
  }

  .modal-content {
    padding: 0;
    width: 100%;

    .head {
      padding: 16px 16px 16px 32px;
      border-radius: 10px;
      margin-bottom: 0;
      @include xpay-lg {
        display: none;
      }
    }
  }
}

.xpay-trade-box {
  border-right: 0px !important;
  display: flex;
  flex-direction: column;
  width: 62%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #d9dfff;
  background: $white;
  padding: 24px;

  @include xpay-pre-lg {
    width: 100%;
    border-radius: 10px;
    border-right: 1px solid #D9DFFF !important;
    padding: 12px;
  }

  .order-box-divider {
    width: calc(100% + 48px);
    transform: translateX(24px);
  }

  .order-tabs {
    display: flex;
    border-radius: 5px;
    background: $light-blue;
    padding: 8px;

    .tab {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      padding: 12px 0px;
      width: 49%;
      border-radius: 5px;
      background: $light-blue;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &.active {
        transition: background-color 0.3s ease;
        background: white;
        cursor: unset;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .input-label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #3f62fe;
        gap: 8px;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      span {
        font-size: 16px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }

    .slider-label {
      display: flex;
      align-items: center;

      .deposit-irt-button {
        margin-top: unset;
      }

      span {
        color: #a8a9aa;
        font-size: 16px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }

    .field-sublabel {
      display: flex;
      align-items: center;
      margin-top: 8px;
      justify-content: space-between;
      border-radius: 5px;
      background: #f7f7f7;
      padding: 10px 16px;

      p {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 100%;
        font-size: 14px;
        font-weight: 400;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }
    }

    .button-label {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }
    }

    .xpay-button {
      margin-top: 8px;
      max-width: unset;

      button {
        span {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
      }
    }

    .field {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      border-radius: 5px;
      border: 1px solid #d9dfff;
      padding: 16px;
      width: 100%;
      background: #fff;
      align-items: center;

      input {
        width: 90%;
        font-size: 20px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        appearance: none;
        -webkit-appearance: none;

        @include xpay-pre-lg {
          font-size: 18px;
        }
      }

      .currency {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .currency-select {
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 8px;
        padding: 8px;
        border-radius: 2.5px;
        background: #f7f7f7;
        cursor: pointer;

        span {
          display: flex;

          font-weight: 600;
        }
      }
    }

    .ant-slider {
      margin-bottom: 60px;

      @include xpay-pre-lg {
        width: 95%;
        margin-inline: auto;
      }
    }
  }
}
