@import 'styles/references/index.scss';

.rejection-message-alert {
  max-width: 480px;
  margin-inline: auto;

  .alert-box .alert-right-side .alert-icon {
    min-height: 56px;
  }

  @include xpay-pre-lg {
    margin-top: 48px!important;
  }
}

.xpay-authentication-two {
  max-width: 480px;
  margin: auto;
  @include xpay-pre-lg {
    margin-top: 105px;
  }

  .step-divider {
    width: calc(100% + 72px);
    transform: translateX(36px);
  }

  .step-warning-content {
    .bg {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      span {
        position: absolute;
        font-weight: 500;
        font-size: 32px;
        color: white;
        margin-top: 10%;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;

      &:last-child {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    span {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      gap: 8px;
      margin-top: 16px;
    }

    .xpay-button {
      margin-top: 24px;
      max-width: unset;
      button {
        max-width: unset;

        span {
          margin: unset;
        }
      }
    }
  }

  .step-agreement-content {
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
    }

    .buttons {
      margin-top: 24px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 16px;

      .xpay-button {
        max-width: unset;
      }
    }
  }

  .step-content {
    .field {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      border-radius: 10px;
      border: 1px solid #ebebeb;
      background: #fcfcfc;
      padding: 16px;
      width: 100%;
      align-items: center;

      input {
        width: 50%;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
        appearance: none;
        font-size: 16px;
        font-weight: 500;
        -webkit-appearance: none;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }

    #retry {
      margin-top: 16px;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      color: #1552F0;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      p {
        color: #25282b;
        text-align: right;
        font-size: 16px;
        font-weight: 500;
      }

      span {
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .telephone-code {
      direction: ltr;
      width: 100% !important;
      margin-top: 16px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > input {
          border-radius: 10px;
          border: 1px solid #ebebeb;
          background: #fcfcfc;
          font-size: 24px;
          color: $black;
          padding: 8px;

          @include xpay-pre-md {
            margin: 0 5px;
          }
        }
      }
    }

    #waiting {
      margin-top: 16px;
      color: #25282b;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      opacity: 0.5;
    }

    #resend {
      margin-top: 16px;
      color: #25282b;
      text-align: right;
      font-size: 16px;
      font-weight: 500;
      opacity: 1;
      cursor: pointer;
    }

    p {
      color: #25282b;
      text-align: right;
      font-size: 16px;
      font-weight: 500;
    }

    #similar {
      color: #1552f0;
      font-size: 16px;
      font-weight: 500;
    }

    #small {
      font-size: 14px;
      font-weight: 500;
    }

    #handwritten {
      margin-top: 24px;
      text-align: right;
      font-size: 16px;
      font-weight: 500;
    }

    .handwritten-prototype {
      margin-top: 16px;
      border-radius: 10px;
      border: 1px solid #d9e5ff;
      background: #f5f8ff;
      padding: 16px;
      p {
        color: #859abc;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        white-space: pre-line;
      }
    }

    .image-upload {
      border-radius: 10px;
      border: 1px dashed #d9e5ff;
      background: #f5f8ff;
      height: 160px;
      margin-top: 24px;

      .ant-upload-btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          color: #859abc;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .province-city {
      display: flex;
      justify-content: space-between;
    }

    .address {
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;
      appearance: none;
      -webkit-appearance: none;
      font-size: 16px;
      font-weight: 500;
      margin: 24px auto;
      max-height: 128px;
      border-radius: 10px;
      border: 1px solid #ebebeb;
      background: #fcfcfc;
      padding: 16px;
      width: 100%;
    }

    .requirements {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 32px;

      .item {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
          color: #859abc;
          text-align: right;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .xpay-button {
      max-width: unset;
      margin-top: 32px;
      button {
        border-radius: 10px;
        background: linear-gradient(95deg, #3f62fe -25.7%, #678af5 112.1%);
      }
    }

    .preview {
      button {
        background: #112a5d;
      }
    }

    .image-submit {
      margin-top: unset;
    }
  }

  &.rejected {
    .card-body {
      .xpay-button {
        max-width: unset;
        width: 100%;
        margin-top: 32px;
        button {
          width: 100%;
        }
      }
      margin: 0;
    }
  }

  &.congratulations {
    padding: 0;
    margin: auto;
    max-width: 480px;
    @include xpay-pre-lg {
      margin-top: 105px;
    }

    .card-body {
      margin: 0;
      width: 100%;
      .step-congratulations {
        display: flex;
        width: 100%;
        flex-direction: column;

        svg {
          margin-inline: auto;
        }

        #tick {
          position: absolute;
          left: 32%;
          top: 54px;
        }

        .step-content {
          padding: 24px;
          h5 {
            margin-bottom: unset;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
          }

          p {
            margin-top: 16px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }

          .xpay-button {
            max-width: unset;
            margin-top: 32px;
            button {
              border-radius: 10px;
              background: linear-gradient(
                95deg,
                #3f62fe -25.7%,
                #678af5 112.1%
              );

              svg {
                margin-inline: unset; //don't modify
              }
            }
          }
        }
      }
    }
  }
}
