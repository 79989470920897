@import 'src/styles/references/index.scss';

.referral-box {
  display: flex;
  width: 100%;
  border: 1px solid #d9dfff;
  grid-area: referral;

  @include xpay-pre-lg {
    width: 100%;
  }

  .card-body {
    @include xpay-pre-lg {
        margin: unset;
        padding-inline: 0;
    }
    .referral-compound {
      display: flex;
      margin: auto;
      border-radius: 5px;
      background-color: $primary;
      width: 100%;
      z-index: -1;

      .referral-banner {
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        object-fit: cover;
        background: url('../../../../assets/images/referral/Referral.png'),
          center / cover no-repeat;
        background-size: 100% 100%;

        @include xpay-pre-lg {
          
        }
      }
      .referral-description {
        display: flex;
        width: 50%;
        background: #496CEB;
        justify-content: center;
        align-items: center;
        padding: 24px;
        border-radius: 5px;

        p {
          margin-right: 16px;
          display: flex;
          font-size: 20px;
          font-weight: 500;
          margin-inline: auto;
          color: $white;
        }
      }
    }
    .referral-info {
      display: grid;
      grid-auto-flow: column;
      column-gap: 16px;
      margin-top: 24px;

      @include xpay-pre-xl {
        grid-auto-flow: row;
        row-gap: 16px;
      }

      @include xpay-pre-lg {
        column-gap: 6px;
        grid-auto-flow: column;
        row-gap: unset;
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        span {
          color: $black;
          font-size: 14px;
          margin: auto;
          white-space: nowrap;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        div {
          display: flex;
          width: 100%;
          height: 50px;
          border-radius: 5px;
          border: 1px solid #d9dfff;
          background: #fafbfc;
          padding: 14px 20px;
          margin: auto;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          text-align: center;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
      }
    }

    .invitation {
      border-radius: 5px;
      border: 1px solid #d9dfff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin-top: 24px;

      span {
        font-size: 14px;
        font-weight: 800;
        color: #a8a9aa;
      }

      a {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;

        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      svg {
        cursor: pointer;
        min-width: 32px;
        min-height: 32px;
      }
    }

    .xpay-button {
      margin-top: 24px;
      width: 100%;
      max-width: unset;

      button {
        span {
          color: $white;
          font-size: 16px;
        }
      }
    }
  }
}
