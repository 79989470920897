@import 'src/styles/references/index.scss';

.xpay-success {
  max-width: 480px;
  margin: auto;
  width: 100%;

  @include xpay-pre-lg {
    margin-top: 80px;
  }

  .mobile-header {
    @include xpay-lg {
      display: none;
    }

    @include xpay-pre-lg {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .success-card {
    padding-top: unset;

    .card-body {
      margin-top: unset;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;

      .header, ul, .buttons {
        position: relative;
        z-index: 3;
      }

      .union {
        position: absolute;
        transform: translate(-50%, 0%);
        top: 0%;
        left: 50%;
        z-index: 2;
      }

      .image-success {
        margin-top: 32px;
      }

      .header {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 3;

        .content {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          h1 {
            color: $black;
            font-size: 16px;
            font-weight: 500 !important;
            text-align: center;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }

          p {
            font-size: 14px;
            text-align: center;
            color: #85A2BC;
            font-weight: 500;

            @include xpay-pre-lg {
              font-size: 12px;
            }
          }
        }
      }

      ul {
        list-style: none;
        padding: 16px;
        width: 100%;
        background: $light-blue-secondary;
        border-radius: 10px;
        margin-top: 32px;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          white-space: nowrap;

          .ant-divider {
            min-width: unset;
          }

          span {
            font-size: 16px;

            @include xpay-pre-lg {
              font-size: 14px;
            }

            &:first-child {
              color: $black;
              font-weight: 500;
            }

            &:last-child {
              font-weight: 500;
              display: flex;
              align-items: center;
              gap: 8px;

              svg {
                cursor: pointer;
              }
            }
          }
        }
      }


      .buttons {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        .xpay-button {
          margin: unset;
          max-width: unset;

          button {
            height: 48px;
            width: 100%;
          }
        }
      }
    }
  }
}