@import 'src/styles/references/index.scss';

.action-box {
    display: grid;
    grid-auto-flow: column;
    grid-area: action;
    padding: 32px 24px;
    width: calc(100% + 64px);
    transform: translateX(32px);
    margin-top: 32px;
    border-top: 1px solid #D9DFFF;
    border-bottom: 1px solid #D9DFFF;
    box-sizing: border-box;

    @include xpay-pre-lg {
        grid-template-rows: auto auto;
        row-gap: 24px;
        background: $white;
        width: 100%;
        margin: 0 auto;
        padding: 24px;
        transform: unset;
        border: none;
        border-radius: 10px;
    }
}