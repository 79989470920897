@import "styles/references/index";

.xpay-register {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .register-banner {
    display: flex;
    position: relative;
    background: url("../../../assets/images/login/union.png");
    background-size: cover;
    width: 45%;
    background-position: center;
    height: 100%;
    overflow: hidden;

    .xpay-x {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .ant-carousel {
      width: 100%;
      height: 100%;
      margin: auto;

      .slick-slider {
        height: 100%;

        .slick-list {
          height: 100% !important;

          .slick-track {
            height: 100%;
          }
        }
      }

      .slick-dots {
        bottom: 12%;

        li {
          margin-inline: 2px
        }

        li, button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #0086FF40;
        }

        .slick-active {
          width: 24px;
          height: 8px;
          background: #0086FF;
          border-radius: 3px;

          button {
            width: 24px;
            height: 8px;
            background: #0086FF;
            border-radius: 3px;
          }

          li {
            button {
              background: $primary-gradient;
            }
          }
        }
      }

      .slick-next, .slick-prev {
        top: unset;
        bottom: 10%;
        width: 40px;
        height: 40px;
        z-index: 20;
      }

      .slick-next {
        right: 5%;
      }

      .slick-prev {
        left: 5%;
      }

      div {
        height: 100%;

        .carousel-slide {
          margin: auto;
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100% !important;
          user-select: none;

          .carousel-image {
            width: 200px;
            height: 200px;
          }

          .carousel-title {
            font-size: 20px;
            margin-top: 48px;
            direction: rtl;
          }

          .carousel-description {
            font-size: 14px;
            color: #909090;
            margin-top: 16px;
            direction: rtl;
            margin-inline: auto;
            width: 75%;
            text-align: center;
          }
        }
      }
    }

    @include xpay-pre-lg {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 40px;
      gap: 8px;
      position: absolute;
      top: 40px;
      left: 40px;
      border-radius: 10px;
      background-color: white;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .register-content {
    display: flex;
    position: relative;
    width: 55%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: white;

    .xpay-heading {
      @include xpay-lg {
        display: none;
      }

      height: 48px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: white;
      padding: 0 16px;

      img {
        height: 16px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        gap: 8px;
        border-radius: 10px;
        background-color: white;

        span {
          font-size: 14px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
      }
    }

    .xpay-mask {
      width: 100%;

      @include xpay-lg {
        display: none;
      }
    }

    @include xpay-pre-lg {
      flex-direction: column;
      width: 100%;
      justify-content: unset;
      padding: 32px;
    }

    .xpay-logo-desktop {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;

      @include xpay-pre-lg {
        display: none;
      }
    }

    .register-form {
      display: flex;
      flex-direction: column;

      @include xpay-pre-lg {
        width: 100%;
      }

      h2 {
        font-size: 32px;
        font-weight: 500;
        text-align: center;

        @include xpay-pre-lg {
          font-size: 18px;
          text-align: right;
          margin-top: 32px;
        }
      }

      p {
        font-size: 14px;
        color: #909090;
        font-weight: 500;
        text-align: center;

        @include xpay-pre-lg {
          font-size: 12px;
          text-align: right;
        }
      }

      .phoneNumber-input {
        margin-top: 32px;
        height: 64px;

        input {
          background: white !important;
        }

        @include xpay-pre-lg {
          margin-top: 16px;
          height: 48px;
        }
      }

      .password-input, .repeatPassword-input {
        margin-top: 24px;
        height: 64px;

        input {
          background: white !important;
        }

        @include xpay-pre-lg {
          margin-top: 16px;
          height: 48px;
        }
      }

      .password-errors {
        list-style: none;
        padding: unset;
        margin: unset;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
          display: flex;
          gap: 4px;
          color: #B20000;
          font-size: 12px;
          font-weight: 500;

          .anticon {
            transform: translateY(-2px)
          }
        }
      }

      .referral {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        span {
          width: fit-content;
          cursor: pointer;
          color: #3772FF;
          font-size: 14px;
          font-weight: 500;
        }

        .referral-input {
          margin-top: 16px;
          height: 64px;

          input {
            background: white !important;
          }
        }
      }

      .warning {
        display: flex;
        align-items: center;
        align-self: flex-start;
        gap: 4px;
        color: #E59700;
        font-size: 12px;
        margin-top: 12px;

        .anticon {
          transform: translateY(-2px);
        }
      }

      .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        .checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }

        a {
          color: $primary;
          font-weight: 500;
          font-size: 12px;
        }

        .has-account {
          display: flex;
          gap: 8px;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .input {
        display: flex;
        width: unset !important;
        direction: ltr;
        margin-top: 32px;
        gap: 8px;

        @include xpay-pre-lg {
          width: 343px;
        }

        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          > input {
            margin: 0 10px;
            border: 1px solid $table-border;
            border-radius: 10px;
            width: 74px !important;
            height: 64px !important;

            @include xpay-pre-md {
              margin: 0 3px;
            }

            @include xpay-pre-lg {
              width: 48px !important;
              height: 48px !important;
            }
          }
        }
      }

      .domain-assurance {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border: 1px solid $table-border;
        border-radius: 8px;
        background: $ant-form-bg;
        gap: 4px;
        @include xpay-lg {
          margin-bottom: 32px;
        }

        svg {
          transform: translateY(-1px);
          fill: $light-info;
          width: 16px;
          height: 16px;
        }

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          color: #909090;

          a {
            text-decoration: none;
            color: $white;
            font-size: 12px;
            transform: translateY(-1px);
            background: $primary-gradient;
            padding: 4px 8px;
            border-radius: 8px;
          }
        }
      }

      .buttons {
        display: flex;
        width: 100%;
        gap: 16px;
        margin-top: 32px;

        @include xpay-pre-lg {
          gap: 8px;
        }

        .code-submit-button {
          max-width: unset;
          width: 75%;

          @include xpay-pre-lg {
            max-width: 207px;
          }

          button {
            height: 64px;

            @include xpay-pre-lg {
              height: 48px;
            }
          }
        }

        .back-button {
          max-width: unset;
          width: 25%;

          @include xpay-pre-lg {
            max-width: 120px;
          }

          &:hover {
            button {

              background: initial;
            }
          }

          button {
            height: 64px;
            max-width: unset;
            width: 100%;
            gap: 8px;

            @include xpay-pre-lg {
              height: 48px;
              max-width: 120px;
            }

            span {
              color: black;
              font-weight: 500;
              font-size: 16px;

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }

            svg {
              @include xpay-pre-lg {
                display: none;
              }
            }
          }
        }
      }

      .register-button {
        max-width: unset;
        margin-top: 32px;

        button {
          height: 64px;

          @include xpay-pre-lg {
            height: 48px;
          }
        }
      }
    }
  }
}