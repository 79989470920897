@import 'src/styles/references/index';

.xpay-bottom-menu-item {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bottom-menu-home-item {
    &.active {
      path {
        stroke: white;
      }
    }
  }

  span {
    margin-top: 16px;
    color: #b3b3b3;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }

  &.active {
    span {
      color: #1552F0;
    }

    svg {
      rect, path {
        stroke: #1552F0;
      }
    }
  }
}
