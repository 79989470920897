@import "src/styles/references/index";

.xpay-badge {
  max-width: 180px;
  width: max-content;

  div {
    border: 0;
    outline: 0;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    height: max-content;
    padding: 2px 16px;
    direction: ltr;
    &:hover {
      cursor: pointer;
    }
    &.success {
      background: $badge-success-bg;
      color: $badge-success-text;
    }
    &.primary {
      background: $primary;
      color: $white;
    }
    &.secondary {
      background: $secondary;
      color: $white;
    }
    &.error {
      background: $badge-error-bg;
      color: $badge-error-text;
    }
    &.waiting {
      background: $badge-waiting-bg;
      color: $badge-waiting-text;
    }
    &.info {
      background: $primary;
      color: $white;
    }

    img {
      margin-left: 5px;
    }
  }
}
