@keyframes xpay-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes xpay-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes xpay-scale-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes xpay-scale-down {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes xpay-blur {
  0% {
    backdrop-filter: blur(0px) brightness(100%);
  }

  100% {
    backdrop-filter: blur(5px) brightness(80%);
  }
}

@keyframes xpay-un-blur {
  0% {
    backdrop-filter: blur(5px) brightness(80%);
  }

  100% {
    backdrop-filter: blur(0px) brightness(100%);
  }
}

@keyframes xpay-grow-down {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes xpay-open-down {
  0% {
    height: 52px;
  }

  100% {
    height: max-content;
  }
}

@keyframes xpay-close-up {
  0% {
    height: 100px;
  }

  100% {
    height: 0;
  }
}
