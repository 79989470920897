@import 'src/styles/references/index';

.xpay-bottom-menu {
  position: fixed;
  display: flex;
  z-index: 999;
  width: 100%;
  height: 115px;
  background: $white;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px 32px;
  bottom: 0;

  @include xpay-lg {
    display: none;
  }
}
