@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    src: url("../En/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    src: url("../En/Roboto-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    src: url("../En/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: bold;
    src: url("../En/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url("../En/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    src: url("../En/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url("../En/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    src: url("../En/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    src: url("../En/Roboto-Thin.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 200;
    src: url("../En/Roboto-ThinItalic.ttf") format("truetype");
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    src: url("../En/Roboto-Regular.ttf") format("truetype");
}