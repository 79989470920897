@import 'src/styles/references/index.scss';

.xpay-dashboard {
  margin: auto auto;
  width: 100vw;
  height: 100vh;
  // max-height: 1080px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 110px;
  scrollbar-width: none;
  padding-bottom: 10%;
  @include xpay-pre-lg {
    scrollbar-width: initial;
    max-height: unset;
    overflow-y: visible;
    overflow-x: hidden;
  }

  .xpay-sidebar {
    @include xpay-pre-md {
      display: none !important;
    }
  }

  @include xpay-pre-md {
    margin: 0 auto !important;
    padding-bottom: 120px !important;
  }
  // @include xpay-pre-xl {
  //   width: 100vw !important;
  // }

  @include xpay-pre-lg {
    width: calc(100% - 60px);
    margin: 0 auto;
    height: auto;
  }

  @include xpay-pre-md {
    width: 100%;
    margin: 0 auto;
    height: auto;
  }
}
