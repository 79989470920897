@import 'styles/references/index';

.xpay-switch-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  flex-wrap: nowrap;

  button {
    border: 1px solid $info;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    height: 48px !important;
    background: transparent;
    color: $info;

    @include xpay-size(height, 40px, 12px);
    &.active {
      background: $primary;
      color: $white;
      border: 0;
    }

    &.button-disable {
      opacity: 0.5;
    }
  }
}
