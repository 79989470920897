.ant-segmented {
    border-radius: 10px !important;

    .ant-segmented-item {
        border-radius: 10px !important;
    }
}
.ant-progress-text {
    right: -50% !important;
}
