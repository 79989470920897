@import 'src/styles/references/index';

.xpay-button {
  max-width: 180px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  &.done {
    margin-top: 20px;
  }

  button {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 48px;

    span {
      margin: 0 5px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disable {
      opacity: 0.4;
    }

    &.success {
      background: $success;
      color: $white;
    }

    &.info {
      background: $waiting-badge;
      color: $warning;
    }

    &.trade {
      background: #dcddff;
      color: #605ae5;
    }

    &.primary {
      background: $primary-gradient;
      color: $white;
    }

    &.secondary {
      background: $secondary;
      color: $white;
    }

    &.error {
      background: $danger;
      color: $white;
    }

    &.info {
      background: $primary;
      color: $white;
    }

    &.card {
      background-color: #F5FAFF;
      border-radius: 5px;
      height: 32px;

      span {
        color: #0086FF;
        font-size: 12px;
      }
    }

    &.outlined {
      background: $white;
      border: 1px solid #d9dfff;
      color: $primary;
      height: 32px;
      transition: 0.4s;
      width: max-content;

      span {
        color: #1552f0;
      }

      &:hover {
        background: $primary;
        transition: background-color 0.4s color 0.4s;

        span {
          color: $white;
        }
      }
    }

    &.card {
      background-color: #F5FAFF;
      border-radius: 5px;
      height: 32px;

      span {
        color: #0086FF;
        font-size: 12px;
        font-weight: 500;
      }

      &:hover {
        span {
          color: white;
        }
        background-color: #1552f0;
      }
    }
  }
}
