@import 'src/styles/references/index';

.xpay-alert {
  margin-inline: auto;
  margin-top: 0;
  width: 100%;
  max-width: 1066px;

  @include xpay-pre-lg {
    top: 6%;
    width: 90%;
  }
}

.xpay-verification-alert {
  .alert-box {
    .alert-right-side {
      .alert-icon {
        min-height: 56px;
      }
    }
  }
}

.xpay-dashboard-main {
  display: grid;
  border-radius: 20px;
  border: 1px solid #d9dfff;
  background: #fff;
  margin: auto;
  max-width: 1066px;
  grid-template-areas:
    'slider slider slider slider slider'
    'action action action action action'
    'referral referral divider wallet wallet'
    'referral referral divider authentication authentication'
    'currencies currencies currencies currencies currencies';
  grid-template-columns: repeat(2, 1fr);
  padding: 32px;
  gap: 24px;

  @include xpay-pre-xl {
    grid-template-areas:
    'slider slider slider slider slider'
    'action action action action action'
    'referral referral referral divider wallet'
    'referral referral referral divider authentication'
  'currencies currencies currencies currencies currencies';
  }

  @include xpay-pre-lg {
    padding: 32px 16px;
    background: $bg;
    border: none;
  }

  @include xpay-pre-lg {
    margin-top: 25%;
    grid-template-areas:
      'slider'
      'action'
      'wallet'
      'currencies'
      'authentication'
      'referral';
    grid-template-columns: repeat(1, 1fr);
  }
}
