@import 'src/styles/references/index';

.xpay-navigation {
  height: 100vh !important;

  .ant-drawer-header {
    .ant-drawer-header-title {
      display: flex;
      justify-content: space-between;

      .ant-drawer-title {
        width: fit-content;
        display: flex;
        flex: unset;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;

    .version-container {
      font-size: 0.75rem;
      padding: 0 24px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      color: $gray-span;
      justify-content: center;
      width: 100%;
    }
  }
}