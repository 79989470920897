@import 'src/styles/references/index.scss';

.xpay-bank-accounts-modal {
  width: 480px;

  .modal-content {
    padding: 0;
    border-radius: 20px;

    > .head {
      display: none;
    }

    .xpay-panel-card {
      border-radius: 20px;

      .head {
        margin-bottom: 16px;

        h3 {
          @include xpay-pre-lg {
            font-size: 12px;
            white-space: nowrap;
          }
        }

        a {
          @include xpay-pre-lg {
            white-space: nowrap;
            height: 32px;
          }
        }
      }

      .card-body {
        margin: unset;
        width: 100%;

        ul {
          list-style: none;
          padding: unset;
          margin: unset;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            padding: 0 10px;
            cursor: pointer;

            svg {
              @include xpay-pre-lg {
                display: none;
              }
            }

            &:hover {
              background: linear-gradient(90deg, #FFFFFF -33.94%, #F4F5FF 100%);
              border-radius: 4px;
            }

            span {
              font-size: 16px;
              font-weight: 500;
            }

            .title {
              display: flex;
              gap: 16px;
              align-items: center;


              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .ant-divider {
            margin: 12px 0;

            @include xpay-pre-lg {
              display: none;
            }
          }
        }
      }
    }
  }
}

.xpay-irt-deposit {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 1066px;

  @include xpay-pre-lg {
    margin-top: 128px;
  }

  .deposit-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 32px;
    border-radius: 20px;
    border: 1px solid $table-border;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
      padding: 16px;
    }

    .ant-divider {
      @include xpay-pre-lg {
        margin-top: 16px;
      }
    }

    .switches {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-blue;
      padding: 6px;
      border-radius: 10px;

      @include xpay-pre-lg {
        height: 64px;
      }

      .switch {
        width: 49.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 55px;
        max-height: 55px;
        border-radius: 5px;
        cursor: pointer;

        @include xpay-pre-lg {
          min-height: 48px;
          max-height: 48px;
        }

        span {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        &.active {
          cursor: initial;
          background-color: white;
        }
      }
    }

    .info-collapse {
      border: unset;
      margin-bottom: 24px;

      .ant-collapse-item {
        border: unset;

        .ant-collapse-header {
          border: 1px solid #1552F0;
          border-radius: 10px;
          background: #1552F00F;
          padding: 16px;

          @include xpay-pre-lg {
            padding: 12px 8px;
          }

          .ant-collapse-header-text {
            color: #678AF5;
            font-weight: 500;
            font-size: 16px;
          }

          &[aria-expanded="true"] {
            border-radius: 10px 10px 0 0;
          }

          .ant-collapse-expand-icon {
            .xpay-button {
              @include xpay-pre-lg {
                width: 96px;
              }

              button {
                background: #678AF5 !important;
                height: 32px;

                span {
                  @include xpay-pre-lg {
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }

        .ant-collapse-content {
          border: unset;
          background: #1552F00F;

          .ant-collapse-content-box {
            border-radius: 0px 0px 10px 10px;
            border: 1px solid #1552F0;
            border-top: unset;

            .info-content {
              display: flex;
              justify-content: flex-start;
              gap: 16px;

              .info-logo {
                background: $primary;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 48px;

                @include xpay-pre-lg {
                  min-width: 40px;
                }
              }

              ul {
                list-style: none;
                padding: unset;
                margin: unset;
                width: 100%;

                li {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  align-items: center;
                  gap: 8px;

                  .ant-divider {
                    min-width: unset;
                    width: unset;
                    flex: 1;
                    border-color: #1552F0;
                    border-style: dashed;
                    border-width: 1px;
                  }

                  p {
                    display: flex;
                    align-items: center;
                    color: #23262F50;
                    font-weight: 500;
                    font-size: 16px;
                    white-space: nowrap;
                    width: fit-content;
                    flex-shrink: 0;
                    font-weight: 500;

                    @include xpay-pre-lg {
                      font-size: 12px;
                    }
                  }

                  span {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    font-size: 16px;
                    font-weight: 500;
                    gap: 2px;

                    .ir {
                      transform: translateY(1px);
                    }

                    @include xpay-pre-lg {
                      font-size: 12px;
                    }
                  }

                  &:last-of-type {
                    span {
                      span {
                        color: #1552F0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .financial-info {
      display: flex;
      justify-content: space-between;
      min-height: 48px;
      max-height: 48px;
      margin-top: 16px;
      gap: 1%;

      @include xpay-pre-lg {
        flex-direction: column;
        max-height: unset;
        gap: 16px;
      }

      .info {
        display: flex;
        border-radius: 10px;
        background-color: $light-blue-secondary;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;

        @include xpay-pre-lg {
          min-height: 40px;
          max-height: 40px;
          justify-content: space-between;
          padding: 0 20px;

        }

        p {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        span {
          color: #3F62FE;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

      }
    }

    .deposit-info {
      list-style: none;
      background: #FAFAFA;
      border-radius: 5px;
      padding: 8px 16px;
      margin-bottom: unset;
      margin-top: 24px;

      .ant-divider {
        margin: 8px 0;
      }

      .deposit-info-item {
        display: flex;
        justify-content: space-between;
        p {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .amount-selection {
      margin-top: 24px;
      display: flex;
      gap: 16px;
      width: 100%;

      .amount-select {
        cursor: pointer;
        background: #0086FF0D;
        height: 40px;
        border-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0086FF;
        font-weight: 500;
      }
    }

    .xpay-input {
      .input-field {
        display: flex;
        align-items: center;
        padding-left: 10px;
        height: 64px;

        input {
          font-size: 16px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 14px;
          }

          &::placeholder {

          }
        }

        .adornment {
          display: flex;
          gap: 8px;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: 700;

            @include xpay-pre-lg {
              font-size: 14px;
            }
          }
        }
      }
    }

    .choose-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      background-color: $light-blue;
      border: 1px solid $light-blue-border;
      border-radius: 10px;
      height: 64px;
      cursor: pointer;
      margin-top: 24px;
      @include xpay-pre-lg {
        border-radius: 2.5px;
      }

      &.chosen {
        background: white;
      }

      p {
        color: #3F62FE;
        font-size: 16px;
        font-weight: 500;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .chosen-card {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 16px;
          font-weight: 500;
          margin-right: 8px;

          @include xpay-pre-lg {
            font-size: 14px;
            white-space: nowrap;
          }

          &:last-child {
            margin-right: 96px;

            &.iban {
              @include xpay-pre-lg {
                max-width: 96px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            @include xpay-pre-lg {
              margin-right: 32px;
            }
          }
        }

      }

    }

    > .xpay-button {
      max-width: unset;
      margin-top: 32px;

      button {
        span {
          @include xpay-pre-lg {
            font-size: 14px;
          }
        }
      }
    }
  }

  .alert-collapse {
    border: unset;
    margin-top: 32px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .ant-collapse-item {
      border: unset;

      .ant-collapse-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #CC8700;
        border-radius: 10px;
        background: #FEF6E6;
        padding: 16px;

        @include xpay-pre-lg {
          padding: 12px 8px;
        }

        .ant-collapse-header-text {
          color: #E59700;
          font-weight: 500;
          font-size: 16px;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        &[aria-expanded="true"] {
          border-radius: 10px 10px 0 0;
        }

        .ant-collapse-expand-icon {
          .xpay-button {
            @include xpay-pre-lg {
              width: 96px;
            }

            button {
              background: #E59700 !important;
              height: 32px;

              span {
                @include xpay-pre-lg {
                  font-size: 12px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }

      .ant-collapse-content {
        border: unset;
        background: #FEF6E6;

        .ant-collapse-content-box {
          border-radius: 0px 0px 10px 10px;
          border: 1px solid #CC8700;
          border-top: unset;

          .alert-content {
            display: flex;
            justify-content: flex-start;
            gap: 16px;

            .alert-logo {
              background-color: #F5A200;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 48px;

              svg path {
                fill: $white
              }

              @include xpay-pre-lg {
                min-width: 40px;
              }
            }

            p {
              font-size: 14px;
              font-weight: 500;

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

  }

  .xpay-faq {
    margin-top: 32px;
    border: 1px solid $table-border;
    border-radius: 20px;

    @include xpay-pre-lg {
      width: 90%;
      margin-inline: auto;
    }

    .head {
      h3 {
        @include xpay-pre-lg {
          font-size: 14px;
        }
      }
    }
  }
}
