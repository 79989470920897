@import 'src/styles/references/index';

.navigation-items-list {
  padding: unset;
  margin-top: 16px;
  width: calc(100% - 48px);
  margin-right: 48px;
  box-sizing: border-box;
  border-right: 1px solid $table-border;

  .xpay-navigation-item-child {
  }
}

.home-item {
  fill: none;

  path {
    fill: $gray-span;
    stroke: $gray-span;
  }
}

.xpay-navigation-item {
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: calc(100% - 32px);
  padding: 0 24px;
  min-height: 56px;
  flex-wrap: nowrap;
  border-radius: 10px;
  justify-content: space-between;

  .content {
    display: flex;
    gap: 16px;
    align-items: center;

    p {
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #859ABC;
    }
  }

  &.active {
    justify-content: space-between;
    border-radius: 10px;
    background: linear-gradient(95deg, #3f62fe -25.7%, #678af5 112.1%);

    .navigation-left-arrow {
      path {
        stroke: $white;
      }
    }

    .content {
      p {
        color: $white;
      }

      > svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}

.xpay-navigation-item-child {
  color: #7A889B;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 32px);
  padding: 0 24px;
  height: 56px;
  flex-wrap: nowrap;
  border-radius: 10px;
  justify-content: space-between;
  margin-right: 16px;

  &.logout {
    margin-top: auto;
    margin-bottom: 16px;
    .title {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  &.active {
    background-color: #D9DFFF
  }

  &:hover {
    background-color: #D9DFFF
  }
}
